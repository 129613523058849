import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleScrollToSection = (sectionId) => {
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <>
      <div className="small_info">
        <div className="container-fluid">
          <div className="d-flex gap-4 justify-content-md-start justify-content-between px-lg-5 px-md-4 px-0">
            <div>
              <img
                src="/assets/images/home/email.svg"
                alt=""
                className="me-2"
              />
              <a
                className="text-decoration-none"
                href="mailto:info@aizelle.org"
              >
                info@aizelle.org
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-xl bg-body-tertiary aizelle_navbar w-100 bg-white">
        <div className="container d-flex justify-content-between align-items-center">
          <Link
            to="/"
            className="navbar-brand d-flex align-items-center nav_width"
          >
            <span className="brand-text aizelle_logo">
              <img
                src="/assets/images/logo/logo.svg"
                alt="aizelle"
                className="img-fluid"
              />
            </span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 align-items-xl-center">
              <li className="nav-item nav_menu">
                <Link className="nav-link menu_padding" to="/">
                  HOME
                </Link>
              </li>
              <li className="nav-item nav_menu">
                <Link
                  to=""
                  className="nav-link menu_padding"
                  onClick={() => handleScrollToSection("whyus")}
                >
                  WHY US
                </Link>
              </li>
              <li className="nav-item nav_menu">
                <Link
                  to=""
                  className="nav-link menu_padding"
                  onClick={() => handleScrollToSection("service")}
                >
                  SERVICE
                </Link>
              </li>
              <li
                className={`nav-item dropdown nav_menu ${
                  showDropdown ? "show" : ""
                }`}
                onClick={handleDropdownToggle}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle menu_padding"
                  id="Dropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={showDropdown ? "true" : "false"}
                >
                  MORE
                </Link>
                <ul className="dropdown-menu" aria-labelledby="Dropdown">
                  <li>
                    <Link
                      to="/free_competition"
                      className="dropdown-item nav_remove"
                    >
                      FREE competition
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/trade-master-cup"
                      className="dropdown-item nav_remove"
                    >
                      trade-master-cup
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item nav_menu login d-xl-none d-block">
                <Link
                  to="https://prop.aizelle.org/login"
                  className="nav-link menu_padding btn aizelle_btn py-2"
                >
                  LOGIN
                </Link>
              </li>
              <li className="nav-item nav_menu login d-xl-none d-block">
                <Link
                  to="https://prop.aizelle.org/register"
                  className="nav-link menu_padding btn aizelle_btn py-2"
                >
                  SIGN UP
                </Link>
              </li>
            </ul>
          </div>

          {/* Desktop-only Login/Sign-Up */}
          <div className=" d-xl-block d-none">
            <div className="nav-item nav_menu login me-2">
              <Link
                to="https://prop.aizelle.org/login"
                className="nav-link menu_padding btn aizelle_btn py-2"
              >
                LOGIN
              </Link>
            </div>
            <div className="nav-item nav_menu login">
              <Link
                to="https://prop.aizelle.org/register"
                className="nav-link menu_padding btn aizelle_btn py-2"
              >
                SIGN UP
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
