import React from "react";
import { useParams } from "react-router-dom";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";

const faqData = [
  {
    id: 1,
    question: "How often will I get my Payout?",
    time: "Updated over a year ago",
    description:
      "Please see the following information regarding the payout details for our various challenges:",
    title: "Evaluation Challenge",
    title1: "Evaluation Challenge Phase 1 and 2:",
    para1:
      "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    title2: "Evaluation Aizelle Account:",
    para2:
      "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    title3: "Express Challenge",
    title4: "Express Challenge Phase:",
    para3:
      "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    title5: "Express Aizelle Account:",
    para4:
      "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    title6: "Stellar Challenge",
    title7: "Stellar 1-Step Challenge Phase: ",
    para5:
      "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    title8: "Stellar 1-Step Aizelle Account: ",
    para6:
      "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    title9: "Stellar 2-Step Challenge Phase 1 and 2: ",
    para7:
      " If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    title10: "Stellar 2-Step Aizelle Account: ",
    para8:
      "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    title11: "Stellar Lite",
    title12: "Stellar Lite Challenge Phase 1 and 2:",
    para9:
      "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    title13: "Stellar Lite Aizelle Account:",
    para10:
      "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 2,
    question: "What Is The Copy Trading Rule at Aizelle?",
    time: "Updated this week",
    description:
      "Aizelle allows traders to do copy trading between Aizelle Challenge Accounts, but these accounts must belong to the same individual, not others. Traders need to ensure that Aizelle's Challenge Account is designated as the Master account, with all other Slave accounts owned by the same individual.",
    description2:
      "​However, here are the guidelines that traders must follow when participating in copy trading:",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    paragraph:
      "Copy trading between multiple Aizelle Challenge Accounts is permitted, provided the total capital allocation across accounts does not exceed our Max allocation of 300k.",
    paragraph2:
      "Copy trading involving a Aizelle Account, whether between Aizelle Accounts or with any other account, is not allowed.",
    img: "/assets/images/new_home/faqid1.jpg",
    para3:
      "Copy trading with other Individuals, including friends and family, account management or Pass your challenge is strictly prohibited. Traders must operate independently and fully comply with Aizelle's policies.",
    para4:
      "Keep in mind that using Cloud-based Third Party Copier Services (Social trader tools, Traders connect, duplikum etc.) to copy trade is not allowed. This will lead to denial of the Aizelle account or reward. You can use a Trade Copier with a VPS to copy trades.",
    para5:
      "If a significant percentage of a trader's trades are identical to one or multiple traders based on the parameters, (Opening Price, Closing price, lot size, lot ratio, symbols, etc.) Aizelle employs an automated flagging system to identify trades that resemble those of one or more other traders. If a trader's account is flagged, it will undergo a manual review. Should this issue be identified, Aizelle reserves the right to terminate all associated accounts.",
    para6:
      "If we discover two or more accounts with a strikingly similar pattern and different criteria of trading style fall within similar categories, this could indicate that group trading is taking place. In such a scenario, all associate accounts will be immediately terminated.",
    para7:
      "If traders do not comply with any of the regulations, they may receive a formal warning or immediate termination from the Aizelle platform, depending on the severity of the case.",
    para8:
      "The purpose of having copy-trading rules is to ensure that all traders are trading fairly and ethically and to prevent any abuse of the copy-trading system. These rules help to maintain a level playing field for all traders and promote a healthy trading environment. By following these rules, traders can benefit from the copy trading system without compromising the integrity of the system.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 3,
    question: "What are the Restricted/Prohibited Trading Strategies?",
    time: "Updated over a year ago",
    description:
      "Aizelle strictly prohibits any form of cheating or exploitation of the platform, as it goes against our Terms of Service (TOS) agreed upon during registration. Traders are urged to thoroughly read our Terms of Service and understand the following guidelines to prevent unintended consequences.",
    description3:
      "Abuse of the System means that trading styles that do not reflect real market trading are not allowed and will result in a breach of our Terms of Service without any warning. Strategies that produce risk-free, consistent profits exclusively on Challenge Accounts are strictly forbidden. Traders are expected to trade on their accounts as if they were Aizelle Accounts. Utilizing strategies that exploit Challenge Accounts will lead to the termination of a Aizelle Trader's Account, whether in the Challenge phase or while a Aizelle Account, Pass Your Challenge, Copy Trading Services or Signal Services are also strictly prohibited, resulting in the denial of any Aizelle Accounts and a permanent ban from all Aizelle services.",
    title: "Example Strategies That Violate Our Terms of Service:",
    title3: "High-Frequency Trading:",
    para9:
      "High-Frequency Trading (HFT) is a trading strategy characterized by the use of sophisticated expert advisors and high-speed telecommunication networks to execute an excessive number of trades within milliseconds to seconds. This strategy aims to capitalize on minuscule price fluctuations and exploit market inefficiencies. While HFT may seem enticing due to its potential for rapid profit generation, it poses significant risks and can have detrimental effects on the market.",
    title4: "Here's why HFT is restricted on the Aizelle platform:",
    para10:
      "HFT trading can distort market prices and create artificial demand or supply. By executing a large volume of trades within seconds, HFT traders can create false impressions of market activity, influencing other participants' decisions and leading to market manipulation. Excessive trading volumes generated by high-frequency trading can disrupt market stability. The rapid influx and outflow of orders can create volatility, leading to erratic price fluctuations and increased market uncertainty, making it challenging for other traders to make informed decisions. Due to huge amounts of trades in a short period of time, the servers usually freeze and create consequences.",
    title5: "Note:",
    para11:
      "If a trader receives a warning for hyperactivity and later engages in HFT (or vice versa), this may result in a more severe penalty, including account suspension. Warnings are cumulative, and the system may immediately suspend accounts in cases of extreme or repeated Hyperactivity or HFT that causes major strain on our servers.",
    title6: "Quick Strike Method:",
    para12:
      "The Quick Strike Method is an ultra-fast trading strategy where traders exploit the financial market by capitalizing on brief market movements through a high volume of trades, typically holding positions for a few seconds. Traders employing this strategy seek to exploit fleeting price fluctuations to secure small, immediate profits. Although the Quick Strike Method offers the potential for rapid financial gains, it also carries inherent risks.",
    para13:
      "While the allure of quick profits is enticing, the Quick Strike Method can exacerbate market volatility and contribute to artificial price movements. This heightened volatility may mislead other market participants, creating a distorted perception of market conditions. As a result, the Quick Strike Method poses challenges to maintaining platform integrity and fairness.",
    title7:
      "Here's why the Quick Strike Method is restricted on the Aizelle platform:",
    para14:
      "The Quick Strike Method is restricted on our platform due to its potential to disrupt market equilibrium and fairness. Characterized by rapid trading and ultra-short holding periods, it raises legal concerns due to its potential to manipulate markets, create unfair advantages, and undermine regulatory objectives. This strategy involves executing numerous trades in seconds, which can inflate trading volumes and mislead other traders, leading to volatile price fluctuations and market instability. In essence, while offering profit opportunities, its legality is questionable due to its potential adverse effects on market integrity and fairness.",
    title8: "Latency Trading:",
    para15:
      "Latency trading refers to the practice of executing trades based on delayed market data or exploiting delays in the execution of trades to secure guaranteed profits. At Aizelle, latency trading is strictly prohibited due to its unethical nature and violation of fair trading practices in the financial markets.",
    title9: "Example: ",
    para16:
      "Latency trading goes against the principles of fair and transparent trading. It undermines the integrity of the financial markets by introducing an element of unfairness and eroding trust among market participants. A latency trader identifies a delay in trade execution and takes advantage of the price discrepancy between the delayed trade execution and the current market price. They execute a large volume of trades within seconds to capitalize on the price difference, creating artificial buying or selling pressure and manipulating the market. By knowingly engaging in such practices, they compromise the fairness and transparency that underpin a healthy trading ecosystem.",
    title10: "Copy Trading From Others:",
    para17:
      "Aizelle allows traders to engage in copy trading from another Aizelle Account, prop firm, or retail broker, provided that the accounts are owned by the same individual. This means that you can copy trades from any account(s) that you own.",
    para18:
      "However, Copy-Trading between multiple accounts not owned by the same individual, including those of relatives, family members, or friends, is strictly prohibited.",
    para19: "To get more details regarding Copy-Trading",
    title11: "Hedging or Group Hedging Across Various Accounts:",
    para20:
      "Hedging is a risk management strategy where a trader opens two opposite trades (buy and sell) on the same asset to reduce potential losses. For example, if the market moves against one trade, the other trade may gain, minimizing overall risk.",
    para21:
      "At Aizelle, hedging is allowed only within the same account. This means traders can place both buy and sell orders on the same asset within one account to hedge their positions.",
    title12: "What is Not Allowed?",
    para22:
      "Hedging across multiple accounts is prohibited. Trading behavior like taking risk of full or close to daily loss limit in one trade will be suspected as a multi-platform hedge with Aizelle. Doing so is not considered a genuine trading strategy and can result in account termination.",
    title13: "​Example of Allowed Hedging:",
    para23:
      "You buy 1 lot of EUR/USD on Account A and simultaneously sell 1 lot of EUR/USD on Account A to hedge the position. This is allowed within the same account.",
    title14: "Example of Prohibited Hedging:",
    para24:
      "You buy 1 lot of EUR/USD on Account A and simultaneously sell 1 lot of EUR/USD on Account B to hedge the position across two accounts. This is not allowed at Aizelle.",
    title15: "Group Hedging Across Various Accounts:",
    para25:
      "Hedging or group hedging across multiple accounts involves opening multiple accounts and placing opposite direction trades on the same asset across those accounts. The aim of this approach is to reduce market risk by taking advantage of price fluctuations. However, this strategy is not considered a legitimate trading practice and is prohibited as it does not reflect proper trading methodology.",
    title16: "Any form of Arbitrage Trading:",
    para26:
      "Arbitrage trading refers to the practice of exploiting price discrepancies or time lags across different markets or platforms to generate risk-free profits. At Aizelle, any form of arbitrage trading is strictly prohibited due to its unethical nature and potential to disrupt fair market conditions.",
    title17: "Example: ",
    para27:
      "Arbitrage trading can distort market prices and hinder the efficient allocation of resources. By capitalizing on price discrepancies, arbitrage traders can cause prices to deviate from their true fundamental values, creating inconsistencies in market pricing. A trader engages in statistical arbitrage by simultaneously buying and selling related instruments based on historical price patterns. Their trading activity distorts the market pricing of these instruments, creating misalignments between their perceived value and their actual worth. Also, Large-scale arbitrage activities can trigger rapid price movements, creating artificial market fluctuations and destabilizing the normal price discovery process.",
    title18: "Tick Scalping:",
    para28:
      "Tick scalping refers to a trading strategy where traders aim to profit from small price fluctuations by executing a high volume of trades within a short time frame. At Aizelle, limitations have been imposed on tick scalping as a result of its capacity for market manipulation and disruptive trading practices.",
    title19: "Example:",
    para29:
      "A tick scalper uses automated trading algorithms to scalp ticks on instruments. By executing trades at lightning speed, they can exploit even the smallest price movements, effectively front-running other market participants and gaining an unfair advantage. The rapid influx of orders and subsequent cancellations can strain market liquidity, making it challenging for other traders to execute their trades at fair prices.",
    title20: "Grid Trading:",
    para30:
      "Grid trading is a strategy where a trader places multiple buy and sell orders at different price levels above and below the current market price. The goal is to profit from price fluctuations as the market moves up and down, hitting various price points.",
    title21: "Why is Grid Trading prohibited at Aizelle?",
    para31:
      "Grid trading is prohibited because it can lead to market manipulation and create artificial activity. It also increases risk, as a large market movement in one direction can trigger many losses simultaneously. This strategy is not in line with Aizelle's fair trading principles.",
    title22: "Example:",
    para32:
      "A trader places multiple buy orders at $100, $105, and $110, and sell orders at $115, $120, and $125. If the market moves between these levels, the trader profits. However, if the market drops sharply below $100, all your buy orders will lose value, which can cause significant losses.",
    title23: "Gambling Behavior",
    title24: "​Gambling Won't Sustain Long-Term",
    para33:
      "Gambling in trading is impulsive, chance-driven behavior without proper analysis or strategy. It relies on luck rather than skill, involving reckless actions like overleveraging and random trades. Unlike disciplined trading, gambling leads to unpredictable and unsustainable results. At Aizelle, we emphasize professionalism, planning, and risk management over such behavior.",
    title25: "All in One is a Gambling",
    para34:
      "Trading behavior like taking the risk of the full or close to the daily loss limit on one or multiple trade(s) running at a time is one kind of gambling. Such trading behavior is not considered a genuine trading strategy and indicates a lack of proper risk management and It is considered one kind of gambling and doesn't sustain for a long time.",
    title26: "Why is Gambling Prohibited at Aizelle",
    para35:
      "Gambling undermines discipline, risk management, and consistent performance, posing financial risks to both traders and the firm. It violates the professional standards we uphold and disrupts the goal of sustainable trading success.",
    para36:
      "To maintain integrity, based on traders' activities, they may be subjected to various restrictions, including but not limited to reduced leverage, imposing a 1% risk limit on all running trades of your initial balance, and account termination. Our goal is to make these Gambling traders a better trader and a better risk manager for a long time. If gambling continues after leverage reduction or if the 1% risk limit rule is ignored at any time, our system can flag the account, resulting in its termination and profit deduction of the violated trades.",
    para37:
      "Remember that professional traders typically risk 1% at a time or utilize 20% to 30% of their total margin, or both yet they manage to earn substantial profits safely and sustainably over the long term. We support skilled, responsible traders to ensure long-term growth. We are here to assist you in becoming a better trader and to help you transform your life positively.",
    img: "/assets/images/new_home/faqid2.jpg",
    title27: "Account Rolling:",
    para38:
      "In proprietary trading, some individuals engage in a tactic known as account rolling, which resembles a form of gambling. They purchase numerous accounts to place high-risk/margin trades without considering market analysis or a trading plan. These traders ignore the essential steps of understanding market trends and developing a trading plan, instead relying on luck or employing any prohibited method to pass challenges. The odds of succeeding are very low, as most accounts fail. By achieving a few accounts to funded status, they aim to profit from one while intentionally risking or sacrificing others—focusing on exploiting the system rather than demonstrating genuine trading skills.",
    para39:
      "We, Aizelle, encourage traders to develop a proper trading plan. Our Scaling Plan is designed to reward disciplined traders who embrace strategic growth, market insight, and consistency. Unlike account rolling, which exploits the system and resembles gambling more than skill, our approach values consistency and market proficiency.",
    title28: "One-sided Betting:",
    para40:
      "One-sided betting is a risky trading strategy where a trader takes one or multiple positions in one direction—buying or selling—without considering market conditions or conducting the proper analysis. At Aizelle, one-sided betting is restricted due to its speculative nature and potential for significant losses. One-sided betting involves continuously selling or buying any instrument without considering fundamental news, economic indicators, or technical analysis that suggests a potential price increase or decrease. This lack of analysis increases the likelihood of entering trades with unfavorable risk-reward ratios.",
    title29: "Example:",
    para41:
      "A trader includes opening 10 small positions or a large single position on the same or multiple assets, all on one side, making it a dangerous practice. This lack of diversification leaves them vulnerable to substantial losses if the instrument price unexpectedly declines.",
    title30: "Hyperactivity:",
    para42:
      "Hyperactivity in trading refers to an excessive level of trading activity by a trader, characterized by the frequent and rapid execution of trades within a short period of time. This also includes frequent modifications to orders, such as adjusting stop-loss or take-profit levels and updating limit orders.",
    title31: "Here's why hyperactivity is restricted on the Aizelle platform:",
    para43:
      "While trading is an essential aspect of our platform, excessive trading actions can lead to some challenges. The primary concern is the potential slowdown of the platform due to the overwhelming number of server messages/logs generated by numerous trades. This can result in delayed trade executions, which can be extremely frustrating for traders. In extreme cases, it might even freeze or crash the whole platform.",
    para44:
      "To ensure that all our traders have a smooth and reliable experience, we're taking measures to avoid hyperactivity. The industry defines an account as hyperactive if it surpasses 200 trades or 2,000 server messages in a single day. This count also includes messages associated with frequent modifications to orders, such as adjusting stop-loss or take-profit levels and updating limit orders.",
    title32: "Consequences of Exceeding the Limit:",
    para45:
      "The Aizelle team will issue the first warning to adjust trading strategies when an account exceeds 2,000 messages for the initial occurrence. Subsequently, a second warning will be sent should the account exceed this message limit once more. If an account reaches this limit for the third time, it will be considered hyperactive, and the account will be breached. Furthermore, if an account generates 15,000 messages in a day, the account will be forcefully disabled to prevent further strain on the system.",
    para46:
      "Please note that warnings are cumulative across accounts. This means that if you receive a first warning on one account and later engage in hyperactivity on another account, you will receive a second warning. If you’ve already received a second warning, any further occurrence of hyperactivity in any account will result in a third warning, leading to the account being breached.",
    para47:
      "Additionally, warnings may be skipped entirely if we detect excessive hyperactivity that causes significant strain on the server, leading to issues for other clients on our platform.",
    title33: "Note:",
    para48:
      "If a trader receives a warning for hyperactivity and later engages in HFT (or vice versa), this may result in a more severe penalty, including account suspension. Warnings are cumulative, and the system may immediately suspend accounts in cases of extreme or repeated Hyperactivity or HFT that causes major strain on our servers.",
    title34: "Use of Platform or Data Freezing Due to Demo Server Errors:",
    para49:
      "The use of any unfair advantage, such as platform or data freezing due to demo server errors, is strictly prohibited. This ensures a level playing field for all traders and prevents misleading or deceiving practices. Traders found engaging in such behavior will be investigated, and appropriate actions, including the revocation of access to our demo servers, may be taken. In the event of server issues, traders are encouraged to report the problem to Aizelle's support team promptly.",
    title35: "​Use of guarantee of profit during the low liquid market:",
    para50:
      "In accordance with Aizelle's commitment to maintaining market integrity and fairness, we have implemented restrictions on the use of a guarantee of profit during low-liquidity market conditions.",
    para51:
      "Low liquidity in financial markets, particularly during the transition from the U.S. to Asian sessions, presents heightened risks of market manipulation. Referred to colloquially as the dead zone, this period is characterized by limited market depth and heightened vulnerability to deceptive trading practices.",
    para52:
      "The implementation of guaranteed adherence to trades during low-liquidity markets enables traders to potentially evade order executions that would occur under normal market conditions. This behavior undermines the authenticity of financial market operations and contravenes the principles of fair and transparent trading upheld by Aizelle.",
    para53:
      "Consequently, this type of trading activity violates Aizelle's Terms of Service.",
    title36: "Account Sharing/Device Sharing:",
    para54:
      "Account sharing refers to the unauthorized practice of sharing or reselling Aizelle accounts with other individuals or entities. Sharing devices with other traders is strictly prohibited, regardless of the relationship. This behavior violates Aizelle's Terms of Service and is strictly prohibited. A zero-tolerance stance towards account sharing or device sharing is maintained due to several reasons related to security, fairness, and compliance.",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 4,
    question: "How can I calculate the Daily Drawdown?",
    time: "Updated over a year ago",
    description:
      "For traders participating in the Evaluation, Express, and Stellar 2-Step Challenges, a daily drawdown of up to 5% is permitted, and for the Stellar Lite Challenge, a daily drawdown of up to 4% of their initial account balance is permitted. However, for traders enrolled in the Stellar 1-Step Challenge, the allowable daily drawdown is limited to 3% of their initial account balance.",
    para1: "To calculate your daily drawdown limit, use the following formula:",
    title1:
      "Daily Loss Limit = Initial Balance × Daily Loss Limit Percentage of Your Enrolled Challenge Account",
    para2:
      "When calculating the daily drawdown, please note that swap charges, commissions, and fees are included in the total amount. If you exceed the daily drawdown limit, your account will be “Paused” and marked with a Daily Loss Limit, and you will no longer be able to trade.",
    title2: "Example 1:",
    para3:
      "You have a Stellar 2-Step Challenge $100,000 Account, and your Daily Loss Limit is 5% of your initial balance. So, $100,000 is considered as the initial balance. Then, your Daily Drawdown will be ($100,000 * 0.05) = $5000",
    title3: "Example 2: ",
    para4:
      "Let’s say you are in profit at a certain time in the day, You can calculate your Drawdown Limit for the day: (Your initial balance × 0.05 + Profit amount in that day)",
    para5:
      "You have a Stellar 2-Step Challenge $100,000 Account, at noon GMT+2 server time, you have made $2,000 profit, so your Daily Drawdown for that day will be ($100,000 * 0.05+ $2000) = $7000. In other words, losing more than $7,000 on Closed or Running trades on that day will violate the Daily Drawdown Limitation.",
    para6:
      "Please keep in mind that your Daily Loss Limit will reset at midnight according to the server time. Therefore, your new daily drawdown for the following day will be calculated as the Initial Balance (in this case, $100,000) multiplied by the Daily Loss Limit Percentage of your enrolled challenge (5%) which is $5,000.",
    para7:
      "Let's consider an example to illustrate this calculation. Suppose you have purchased an Evaluation $100,000 account. Here's how the calculation works:",
    title4: "Case 01:",
    para8:
      "As per the challenge model, the maximum Daily Loss Limit is $5,000, and you are not allowed to lose more than $5,000 on any given day. Suppose you have lost a total of $3000 in your closed trades. In such a case, you cannot lose more than $2000, including floating losses. Note that Swap, commission rates, and fees are included in this calculation. It will be considered a violation if you lose more than $2,000 on that day.",
    title5: "Case 02:",
    para9:
      "Similarly, let's say you gained $5,000 in profit in a single day. In such a case, you will be allowed to lose $5,000 (your profit) + $5,000 (your Daily Loss Limit) = $10,000 for that day. Losing more than $10,000 (in both open and closed positions) will be considered a violation. Note that, your Daily Loss Limit will reset at midnight according to the server time. So, the gained profit of previous days will not count forward and your daily drawdown will again be the Initial Balance (in this case, $100,000) multiplied by the Daily Loss Limit Percentage of your enrolled challenge (For example: 5%).",
    para10:
      "So, if you started with $110,000 on a new day and you lost $5000, although your account equity is $105,000, you will breach your account cause: $100,000 (Initial balance) × 5% (Daily Loss Limit Percentage)= $5000 is your daily loss limit. It is not required to have equity dropped to $95,000. As long as you exceed the $5000 loss limit on that day your account will be paused due to the daily loss limit being breached.",
    title6: "Case 03:",
    para11:
      "Let's look at another case: You have a $100,000 trading account. In a single day, you encounter a significant loss of around $3,000. Determined to be profitable, you initiate another trade. At any given moment, if the running position exceeds the floating loss of -$2,000. Your account will breach the daily loss limit as you have crossed $5,000 for a single day and your account will be paused.",
    para12:
      "The rule of the Daily Loss Limit implies that if your trades (even floating losses) exceed -$5,000 within any trading day, you're violating the rule. Therefore, regardless of the outcome of your last trade, hitting the $5,000 loss threshold ends your trading journey.",
    title7: "Case 04:",
    para13:
      "Another thing to keep in mind is that your daily loss limit will be reset at midnight according to the server time. For example, suppose you have gained a $2000 profit in a closed trade, and currently, you have a running trade at a $6,000 floating loss. In this case, you still have not violated your Daily Drawdown for the day because your current daily loss is +$2000 - $6000 = -$4000. However, if you hold this one trade with a floating loss of $6,000 after midnight, you will breach the Daily Loss Limit because a new day begins after midnight, and you have already lost $6,000, which is more than your calculated Daily Loss Limit of $5000.",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 5,
    question: "Is News Trading allowed at Aizelle?",
    time: "Updated over a year ago",
    para1:
      "Aizelle allows traders to trade during news events in both the Challenge Phase and Aizelle Account of Evaluation Challenge, Stellar Challenge, and Stellar Lite Challenge.",
    para2:
      "However, in the Express Challenge (both on your Challenge phase and Aizelle account), you are restricted from opening and closing any trade 5 minutes before and after the news. This includes both Market Execution and opening/closing Pending Orders (including take profit and stop loss).",
    para3: "To know more about Express News trading rules, kindly click here.",
    title1: "Disclaimer:",
    para4:
      "Trading during news events involves rapid market changes, high volatility, and carries a significant risk of loss. The requested price for Stop Loss, Take Profit, or any other Pending Orders may or may not be respected during this time. Always trade with caution, and ensure you fully understand the risks involved before participating in news trading.",
    // description:
    //   "Please see the following information regarding the payout details for our various challenges:",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 6,
    question: "How can I complete the KYC verification process?",
    time: "Updated over a year ago",
    description:
      "Completing the KYC (Know Your Customer) verification process is a necessary step to ensure the security and compliance of our trading platform. Here's a detailed guide on how to complete the process:",
    title1: "Step 1:",
    para1:
      "Once you have successfully completed the challenge phases, you will receive a notification on your dashboard prompting you to initiate the KYC verification process. Simply click on the Verify Now button provided, and you will be directed to the Verification Center page. Alternatively, you can access the Verification Center through the My Profile section of your account.",
    title2: "Step 2:",
    para2:
      "On the Verification Center page, you will find instructions along with the Start Verification button. Click on this button to reveal two options for verification: scanning the QR code or sending a verification link via SMS. Choose the option that is most convenient for you and follow the provided instructions to obtain the link for document submission.",
    title3: "Step 3:",
    para3:
      "To complete the KYC verification process, you will need to provide one of the specific documents mentioned.",
    para4: "The following documents should be valid photo IDs:",
    para5:
      "KYC verification cannot be completed if you provide expired documents. Please ensure that you submit the most recent and valid documents to proceed with the verification process.",
    title4: "Step 4:",
    para6:
      " Once you have gathered the necessary documents, submit them through the provided link. After successful submission, you will receive a notification on your dashboard confirming that your verification data has been received. Click on the Continue button to proceed to the next step, where you will be directed to the Agreement Signing page in the Verification Center of your dashboard profile. Here, you will have to enter your full name and address and agree to the terms and conditions. After that, click Submit.",
    title5: "Step 5:",
    para7:
      "After completing all the required steps, a window will appear indicating that your documents are under review. The verification process typically takes 24–48 hours. Upon successful completion, you will receive a notification in the verification center confirming that your verification has been successful. At this point, you will be eligible to receive your Aizelle Account.",
    para8:
      "It's important to note that failure to pass the KYC verification process will result in the rejection of your Aizelle Account application. Should you encounter any difficulties or have questions during the KYC verification process, please reach out to the Aizelle Support Team for guidance and assistance.",
    para9:
      "Please find a visual representation of the process in the video below for your reference:",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 7,
    question: "What will be my Profit Share from the Stellar Lite Challenge?",
    time: "Updated over a year ago",
    description:
      "Aizelle doesn't offer any Profit Share from Challenge phase in the Stellar Lite Plan. It means traders won't get any 15% Profit Share from the Challenge phase of the Stellar Lite Plan.",
    para1:
      "However, upon reaching the Aizelle Account, traders will get an 80% Profit Share and can be eligible for a 90% Profit Share through",
    para2:
      "For example, if a trader has a $100,000 Stellar Lite Account, the trader won't receive any Profit Share from the Challenge phase. However, the trader will receive a 80% Profit Share upon reaching the Aizelle Account. ",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 8,
    question: "Does Aizelle offer a Scale-Up plan?",
    time: "Updated over a year ago",
    para1:
      "Aizelle provides a Scale-Up plan for traders who consistently demonstrate success and follow the trading rules.",
    para2:
      "The Scale-Up plan presents traders with an opportunity to expand their trading activity by potentially receiving a capital allocation of up to 4 million. Skilled and disciplined traders seeking to elevate their trading endeavors can benefit from Aizelle's scaling-up plan. The eligibility criteria for the Evaluation, Express, Stellar, or Stellar Lite accounts remain consistent to simplify the process. The trader must ensure that all trades are closed and should contact support immediately after completing their trading cycle before initiating any new trades.",
    para3: "Review Process & Eligibility Criteria:",
    para4:
      "To be considered for scaling up, traders must meet the following eligibility criteria in a Aizelle account over a",
    para5: "Let's illustrate the scaling-up process with an example.",
    img: "/assets/images/new_home/faqid3.png",
    para6:
      "Based on the trader's performance over the four consecutive months and if all other criteria are met, Aizelle will review the trader's account and potentially Scale it up by 40%. So, with the 40% Scale-Up, the new balance is $140,000.",
    para10: "Therefore, considering the new account balance:",
    para7:
      "Scaling up with Aizelle is a straightforward process that opens doors to enhanced opportunities for traders.",
    para8:
      "What if a trader managed to reach 10% of their profit target for Scaling Up, but was unable to make a profit in the last trading cycle?",
    para9:
      "For instance, let's consider a trader who began trading on January 1st. After four consecutive months (January, February, March, and April), the trader managed to achieve a 10% profit by the end of April. However, during the last trading cycle, the trader was unable to end in profit. As the trader was unable to end his last trading cycle in profit, the trader will not be eligible for the Scale-Up plan. In such a scenario, the trader's account will be evaluated again in May, following the completion of their trading cycle (February, March, April, and May). Essentially, if the trader meets all the criteria during the most recent four-month period, they will be eligible for the Scale-Up plan.",
    // description:
    //   "Please see the following information regarding the payout details for our various challenges:",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 9,
    question: "How can I Withdraw my profits?",
    time: "Updated yesterday",
    description:
      "With Aizelle, withdrawing profits is a straightforward and efficient process. Once traders fulfill all the criteria of their Trading Cycle and achieve profitability, they can easily request a Withdrawal.",
    para1: "Here's what you need to do:",
    para2:
      "Note: If traders need to change the verification method after arriving on the OTP page, please kindly click the cancel button.",
    img: "/assets/images/new_home/faqid4.png",
    para3: "What are the available withdrawal methods?",
    para4:
      "Aizelle offers a variety of withdrawal methods to ensure traders have flexibility and convenience when accessing their profits. These options include USDT (ERC20, TRC20), USDC (ERC20), RiseWorks, and TC Pay, which is exclusively available for our Iranian clients. With these diverse methods, you can choose the one that best suits your needs and enjoy seamless withdrawals.",
    img1: "/assets/images/new_home/faqid5.png",
    para5:
      "Traders can choose the method that best aligns with their preferences and requirements.",
    para8: "How much time would it take to process the withdrawal?",
    para6:
      "Once traders submit their withdrawal request, Aizelle's dedicated team promptly processes and disburses their profits efficiently. As part of Aizelle's commitment to quality service, payouts are issued within 24 hours. It's important for traders to be aware that they are responsible for covering any transfer gateway charges incurred during the process.",
    para7:
      "Please note that the trader is responsible for covering all transfer gateway charges. For more details, please check our",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
  {
    id: 10,
    question: "Aizelle Free Trial Rules",
    time: "Updated over a year ago",
    description:
      "The Free Trial Account at Aizelle is a unique opportunity for participants to experience real trading conditions without financial risks. It's designed for traders to showcase their skills and understand our platform better. Free Trial Accounts follow a 1-step process.",
    para1: "Here's everything you need to know about Free Trial Accounts:",
    title1: "Registration:",
    para2:
      "Each trader in the Free Trial is permitted to register only one account at a time. This account must be unique and associated with a single email and IP address. Once your account is disabled, you may request another, ensuring an opportunity for unlimited free trials while maintaining the integrity of a single, active account policy. After free trial sign-up, users will receive the new account email.",
    title2: "Profit Target:",
    para3:
      "The objective for each trader is to achieve a 5% profit on their initial account balance. This target is set to encourage strategic trading and effective risk management.",
    title3: "Minimum Trading Days Requirement: ",
    para4:
      "Traders are required to trade for a minimum of 3 days within the 14-day trial period. This ensures active participation and provides a fair evaluation of trading skills.",
    title4: "Daily Loss Limit:",
    para5:
      "The Free Trial Account has a Daily Loss Limit of 5% of the account balance. This limit refreshes every day after midnight according to the server time.",
    title5: "Overall Loss Limit:",
    para6:
      "The Free Trial Account has an Overall Loss Limit of 10% of the account balance.",
    title6: "Time Limit:",
    para7:
      "Traders will receive 14 calendar days from the date of the first trade to reach the profit target. After this period, the account will be automatically disabled.",
    title7: "Weekend Position Holding:",
    para8: "There are no restrictions on holding positions over the weekend.",
    title8: "Reset Option Availability:",
    para9:
      "The Free Trial Account does not offer the option to reset the account.",
    title9: "Account Balance Options:",
    para10:
      "Account balance options range from $6,000 to $200,000, accommodating various levels of trading experience and strategies.",
    title10: "Account Type and Leverage:",
    para11:
      "The Free Trial Accounts are swap accounts with a leverage setting of 1:100 for forex instruments, 1:40 for commodities, and 1:20 for indices. This standard leverage is consistent with many professional trading environments.",
    title11: "Trading Platform:",
    para12: "All trading activities are to be conducted on the MT5 platform.",
    title12: "News Trading: ",
    para13:
      "The use of news trading is permitted, allowing traders to fully explore various trading methodologies.",
    title13: "Expert Advisors (EAs):",
    para14:
      "Usage of EAs is not permissible in the Free Trial Accounts. Traders using Expert Advisors will face restrictions on opening any new trades.",
    title14: "Maximum Number of Trades:",
    para15:
      "The maximum number of open positions is 30 at a time. If traders try to open a new position after having 30 open trades, they will not be allowed to open any new trades until the existing trades are closed.",
    para16: "For more information, kindly refer to the image below:",
    img: "/assets/images/new_home/faqid6.png",
    para17:
      "Compliance with these rules is essential for fair and successful participation in the Aizelle Free Trial Accounts. Disregarding any of these rules may lead to disqualification from the trial and affect future participation opportunities.",
    // title: "Evaluation Challenge",
    // topic1: "Evaluation Challenge Phase 1 and 2:",
    // paragraph:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle account.",
    // topic2: "Evaluation Aizelle Account",
    // paragraph2:
    //   "Once you receive your Aizelle account, your Profit Share will be increased to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // topic3: "Express Challenge Phase:",
    // paragraph3:
    //   "If you comply with all the rules, you will receive a 15% Profit Share every trading cycle (28 days) until you gain 25% profit in your Challenge Account.",
    // topic4: "Express Aizelle Account:",
    // paragraph4:
    //   "After achieving a 25% profit, you will be given an Express Aizelle account, and your Profit Share will increase. In the first withdrawal, you'll get 60% of the profit; after that, 75%, and 90% of the Profit Share will be applied consecutively in each trading cycle (28 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // title2: "Stellar Challenge",
    // topic5: "Stellar 1-Step Challenge Phase",
    // paragraph5:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from the Challenge Phase, which will be withdrawable once you have gained 10% growth in your Aizelle Account.",
    // topic6: "Stellar 1-Step Aizelle Account:",
    // paragraph6:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 90%. Starting from the first cycle, you will receive payouts every 5 business days. Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account.",
    // topic7: "Stellar 2-Step Challenge Phase 1 and 2:",
    // paragraph7:
    //   "If you comply with all the rules, you will receive a 15% Profit Share from Phases 1 and 2, which will be withdrawable once you have gained 5% growth in your Aizelle Account.",
    // topic8: "Stellar 2-Step Aizelle Account:",
    // paragraph8:
    //   "Once you receive your Aizelle Account, your Profit Share will increase to 80%. Your first payout will be after four trading weeks (28 days), and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your first payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
    // title3: "Stellar Lite",
    // topic9: "Stellar Lite Challenge Phase 1 and 2:",
    // paragraph9:
    //   "There is no Profit Share from the Challenge Phase in Stellar Lite Account model.",
    // topic10: "Stellar Lite Aizelle Account:",
    // paragraph10:
    //   "Once you receive your Aizelle Account, your Profit Share will be increased to 80%. Your first payout will be after 21 days, and subsequently, you will receive payouts every two trading weeks (14 days). Additionally, you may request your Reward Bonus for the subscription fee with your third payout from the Aizelle Account. Your Aizelle Account Profit Share will be 90% once all the scale-up criteria are fulfilled.",
  },
];

export default function Articles() {
  const { id } = useParams();
  const article = faqData.find((faq) => faq.id === parseInt(id, 10));

  if (!article) {
    return <p>Article not found</p>;
  }

  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_padding">
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h2 className="heading_h4 mb-xl-4 mb-3">{article.question}</h2>
                <p className="font_14 text-secondary mb-xl-4 mb-3">
                  {article.time}
                </p>
                <p className="sub_para">{article.description}</p>
                {article.description2 ? (
                  <p className="sub_para">{article.description2}</p>
                ) : null}
                {article.description3 ? (
                  <p className="sub_para">{article.description3}</p>
                ) : null}

                {/* Rendering content based on the article ID */}
                {id === "1" ? (
                  <>
                    {/* Multiple boxes for id = 1 */}
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title}
                      </h5>
                      <p className="sub_para">
                        <b>{article.title1}</b> {article.para1}
                      </p>
                      <p className="sub_para">
                        <b>{article.title2}</b> {article.para2}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title3}
                      </h5>
                      <p className="sub_para">
                        <b>{article.title4}</b> {article.para3}
                      </p>
                      <p className="sub_para">
                        <b>{article.title5}</b> {article.para4}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title6}
                      </h5>
                      <p className="sub_para">
                        <b>{article.title7}</b> {article.para5}
                      </p>
                      <p className="sub_para">
                        <b>{article.title8}</b> {article.para6}
                      </p>
                      <p className="sub_para">
                        <b>{article.title9}</b> {article.para7}
                      </p>
                      <p className="sub_para">
                        <b>{article.title10}</b> {article.para8}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title11}
                      </h5>
                      <p className="sub_para">
                        <b>{article.title12}</b> {article.para9}
                      </p>
                      <p className="sub_para">
                        <b>{article.title13}</b> {article.para10}
                      </p>
                    </div>
                  </>
                ) : id === "2" ? (
                  <>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title}
                      </h5>
                      <p className="sub_para">{article.paragraph}</p>
                      <p className="sub_para">{article.paragraph2}</p>
                      {/* {article.img && <img src={article.img} alt="Article Image" className="img-fluid mb-4" />} */}
                      <p className="sub_para">{article.para3}</p>
                      <p className="sub_para">{article.para4}</p>
                      <p className="sub_para">{article.para5}</p>
                      <p className="sub_para">{article.para6}</p>
                    </div>
                    <p className="sub_para">{article.para7}</p>
                    <p className="sub_para">{article.para8}</p>
                  </>
                ) : id === "3" ? (
                  <>
                    <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                      {article.title}
                    </h5>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title3}
                      </h5>
                      <p className="sub_para"> {article.para9}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title4}
                      </h5>
                      <p className="sub_para"> {article.para10}</p>
                      <p className="sub_para">
                        <b>{article.title5}</b> {article.para11}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title6}
                      </h5>
                      <p className="sub_para"> {article.para12}</p>
                      <p className="sub_para"> {article.para13}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title7}
                      </h5>
                      <p className="sub_para"> {article.para14}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title8}
                      </h5>
                      <p className="sub_para"> {article.para15}</p>
                      <p className="sub_para">
                        <b>{article.title9}</b> {article.para16}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title10}
                      </h5>
                      <p className="sub_para"> {article.para17}</p>
                      <p className="sub_para"> {article.para18}</p>
                      <p className="sub_para"> {article.para19}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title11}
                      </h5>
                      <p className="sub_para"> {article.para20}</p>
                      <p className="sub_para"> {article.para21}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title12}
                      </h5>
                      <p className="sub_para"> {article.para22}</p>
                      <p className="sub_para mb-4">
                        <b>{article.title13}</b> {article.para23}
                      </p>
                      <p className="sub_para mb-4">
                        <b>{article.title14}</b> {article.para24}
                      </p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title15}
                      </h5>
                      <p className="sub_para"> {article.para25}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title16}
                      </h5>
                      <p className="sub_para"> {article.para26}</p>
                      <p className="sub_para">
                        <b>{article.title17}</b> {article.para27}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title18}
                      </h5>
                      <p className="sub_para"> {article.para28}</p>
                      <p className="sub_para">
                        <b>{article.title19}</b> {article.para29}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title20}
                      </h5>
                      <p className="sub_para"> {article.para30}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title21}
                      </h5>
                      <p className="sub_para"> {article.para31}</p>
                      <p className="sub_para">
                        <b>{article.title22}</b> {article.para32}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title23}
                      </h5>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title24}
                      </h5>
                      <p className="sub_para"> {article.para33}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title25}
                      </h5>
                      <p className="sub_para"> {article.para34}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title26}
                      </h5>
                      <p className="sub_para"> {article.para35}</p>
                      <p className="sub_para"> {article.para36}</p>
                      <p className="sub_para"> {article.para37}</p>
                    </div>
                    <div className="mb-3">
                      {/* {article.img ? <img src={article.img} alt="Article Image" className="img-fluid mb-4" /> : null} */}
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title27}
                      </h5>
                      <p className="sub_para"> {article.para38}</p>
                      <p className="sub_para"> {article.para39}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title28}
                      </h5>
                      <p className="sub_para"> {article.para40}</p>
                      <p className="sub_para">
                        <b>{article.title29}</b> {article.para41}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title30}
                      </h5>
                      <p className="sub_para"> {article.para42}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title31}
                      </h5>
                      <p className="sub_para"> {article.para43}</p>
                      <p className="sub_para"> {article.para44}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title32}
                      </h5>
                      <p className="sub_para"> {article.para45}</p>
                      <p className="sub_para"> {article.para46}</p>
                      <p className="sub_para"> {article.para47}</p>
                      <p className="sub_para">
                        <b>{article.title33}</b> {article.para48}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title34}
                      </h5>
                      <p className="sub_para"> {article.para49}</p>
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title35}
                      </h5>
                      <p className="sub_para"> {article.para50}</p>
                      <p className="sub_para"> {article.para51}</p>
                      <p className="sub_para"> {article.para52}</p>
                      <p className="sub_para"> {article.para53}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title36}
                      </h5>
                      <p className="sub_para"> {article.para54}</p>
                    </div>
                  </>
                ) : id === "4" ? (
                  <>
                    <p className="sub_para"> {article.para1}</p>
                    <h5 className="faq_para mb-lg-3 mb-2">{article.title1}</h5>
                    <p className="sub_para"> {article.para2}</p>
                    <h5 className="faq_para mb-lg-3 mb-2">{article.title2}</h5>
                    <p className="sub_para"> {article.para3}</p>
                    <h5 className="faq_para mb-lg-3 mb-2">{article.title3}</h5>
                    <p className="sub_para"> {article.para4}</p>
                    <p className="sub_para"> {article.para5}</p>
                    <p className="sub_para"> {article.para6}</p>
                    <p className="sub_para"> {article.para7}</p>

                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title4}
                      </h5>
                      <p className="sub_para"> {article.para8}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title5}
                      </h5>
                      <p className="sub_para"> {article.para9}</p>
                      <p className="sub_para"> {article.para10}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title6}
                      </h5>
                      <p className="sub_para"> {article.para11}</p>
                      <p className="sub_para"> {article.para12}</p>
                    </div>
                    <div className="article_box mb-3">
                      <h5 className="heading_h5 fw-bold mb-lg-3 mb-2">
                        {article.title7}
                      </h5>
                      <p className="sub_para"> {article.para13}</p>
                    </div>
                  </>
                ) : id === "5" ? (
                  <>
                    <p className="sub_para"> {article.para1}</p>
                    <p className="sub_para"> {article.para2}</p>
                    <p className="sub_para"> {article.para3}</p>
                    <h5 className="faq_para mb-lg-3 mb-2">{article.title1}</h5>
                    <p className="sub_para"> {article.para4}</p>
                  </>
                ) : id === "6" ? (
                  <>
                    <div className="article_box mb-3">
                      <p className="sub_para">
                        <b>{article.title1}</b> {article.para1}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <p className="sub_para">
                        <b>{article.title2}</b> {article.para2}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <p className="sub_para">
                        <b>{article.title3}</b> {article.para3}
                      </p>
                      <p className="sub_para"> {article.para4}</p>
                      <li className="faq_para fw-bold mb-2">National ID.</li>
                      <li className="faq_para fw-bold mb-2">Passport.</li>
                      <li className="faq_para fw-bold mb-4">
                        Driving License.
                      </li>
                      <p className="sub_para"> {article.para5}</p>
                    </div>
                    <div className="article_box mb-3">
                      <p className="sub_para">
                        <b>{article.title4}</b> {article.para6}
                      </p>
                    </div>
                    <div className="article_box mb-3">
                      <p className="sub_para">
                        <b>{article.title5}</b> {article.para7}
                      </p>
                    </div>
                    <p className="sub_para"> {article.para8}</p>
                    <p className="faq_para"> {article.para9}</p>
                    {/* <div className="mb-3">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/idG2F0G3rd8?si=OfsE5HezTyDSqj3P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div> */}
                  </>
                ) : id === "7" ? (
                  <>
                    <p className="sub_para mt-4">
                      {article.para1} <a href="#"> Scale-Up.</a>
                    </p>
                    <div className="article_box mt-4 mb-3">
                      <p className="sub_para">{article.para2}</p>
                    </div>
                  </>
                ) : id === "8" ? (
                  <>
                    <p className="faq_para mt-3 mb-3">{article.para1}</p>
                    <p className="sub_para mt-3 mb-3">{article.para2}</p>
                    <p className="faq_para mt-3">{article.para3}</p>
                    <p className="sub_para mt-3">
                      {article.para4} <b>four-month period:</b>
                    </p>
                    <div className="article_box mt-4 mb-3">
                      <li className="faq_para">
                        Achieve a minimum accumulated account growth of 10% in
                        four consecutive months.
                      </li>
                      <li className="faq_para">
                        Receive at least two payouts within these successive
                        four months.
                      </li>
                      <li className="faq_para">
                        The last trading cycle should end in profit.
                      </li>
                    </div>
                    <p className="sub_para mt-4">
                      Aizelle accounts (Evaluation, Express, Stellar or Stellar
                      Lite) undergo a review every four months. Traders who meet
                      the eligibility criteria are eligible for a <b>40%</b>{" "}
                      increase in the account balance, up to a maximum of{" "}
                      <b>4 million.</b>
                    </p>
                    <p className="faq_para mt-3 mb-3">{article.para5}</p>
                    {/* {article.img ? <img src={article.img} alt="Article Image" className="img-fluid mb-4" /> : null} */}
                    <p className="sub_para mt-3">{article.para6}</p>
                    <p className="faq_para mt-3 mb-3">{article.para10}</p>
                    <div className="article_box mt-4 mb-3">
                      <li className="sub_para">
                        The Daily Loss Limit is 5% (4% in Stellar Lite) of
                        $140,000, which amounts to $7,000 ($5,600 in Stellar
                        Lite).
                      </li>
                      <li className="sub_para">
                        The Overall Loss Limit is 10% (8% in Stellar Lite) of
                        $140,000, which equates to $14,000 ($11,200 in Stellar
                        Lite).
                      </li>
                      <li className="sub_para">
                        The new Scale-Up target for the next four months is a
                        10% account growth of $140,000.
                      </li>
                    </div>
                    <p className="sub_para mt-3 mb-3">{article.para7}</p>
                    <p className="faq_para mt-3 mb-3">{article.para8}</p>
                    <p className="sub_para mt-3 mb-3">{article.para9}</p>
                  </>
                ) : id === "9" ? (
                  <>
                    <p className="faq_para mt-3 mb-3">{article.para1}</p>
                    <ol>
                      <li className="sub_para">
                        Navigate to the particular account Dashboard from the
                        Accounts section.
                      </li>
                      <li className="sub_para">
                        Then, navigate to the "Payout" section of the Dashboard.
                      </li>
                      <li className="sub_para">
                        Click on the "Payout Request" button, as shown in the
                        visual reference provided below.
                      </li>
                      <li className="sub_para">
                        An OTP will be sent to the registered email address to
                        confirm the request. Please enter the OTP in the
                        designated area to proceed.
                      </li>
                    </ol>
                    <p className="sub_para mt-3 mb-3">{article.para2}</p>
                    {/* {article.img ? <img src={article.img} alt="Article Image" className="img-fluid mb-4" /> : null} */}
                    <p className="faq_para mt-3 mb-3">{article.para3}</p>
                    <p className="sub_para mt-3 mb-3">{article.para4}</p>
                    {/* {article.img1 ? <img src={article.img1} alt="Article Image" className="img-fluid mb-4" /> : null} */}
                    <p className="sub_para mt-3 mb-3">{article.para5}</p>
                    <p className="faq_para mt-3 mb-3">{article.para8}</p>
                    <p className="sub_para mt-3 mb-3">{article.para6}</p>
                    <p className="faq_para mt-3 mb-3">{article.para7}</p>
                  </>
                ) : id === "10" ? (
                  <>
                    <p className="sub_para mt-3 mb-3">{article.para1}</p>
                    <div className="article_box mb-3">
                      <p className="sub_para">
                        {" "}
                        <b>{article.title1}</b> {article.para2}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title2}</b> {article.para3}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title3}</b> {article.para4}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title4}</b> {article.para5}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title5}</b> {article.para6}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title6}</b> {article.para7}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title7}</b> {article.para8}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title8}</b> {article.para9}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title9}</b> {article.para10}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title10}</b> {article.para11}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title11}</b> {article.para12}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title12}</b> {article.para13}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title13}</b> {article.para14}
                      </p>
                      <p className="sub_para">
                        {" "}
                        <b>{article.title14}</b> {article.para15}
                      </p>
                    </div>
                    <p className="faq_para mt-3 mb-3">{article.para16}</p>
                    {/* {article.img ? <img src={article.img} alt="Article Image" className="img-fluid mb-4" /> : null} */}
                    <p className="sub_para mt-3 mb-3">{article.para17}</p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </ScrollToTop>
    </>
  );
}
