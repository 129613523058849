import { useRef, useState } from "react";
import PaymentSwiper from "./PaymentSwiper";

export default function PackageItems() {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedStep, setSelectedStep] = useState("step-2");
  const [activePackage, setActivePackage] = useState(1);
  const tabsSectionRef = useRef(null);
  const tabsData = [
    {
      headerData2: [
        { label: "Account Size" },
        { label: "$6,000", price: "fee: $49" },
        { label: "$15,000", price: "fee: $99" },
        { label: "$25,000", price: "fee: $199" },
        { label: "$50,000", price: "fee: $299" },
        { label: "$100,000", price: "fee: $449" },
        { label: "$200,000", price: "fee: $799" },
      ],
      
     
      step1Data: [
        [
          "Profit Target",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
        ],
        [
          "Time",
          "1st Phase- 4 weeks , 2nd Phase – 8 week",
          "1st Phase- 4 weeks , 2nd Phase – 8 week",
          "1st Phase- 4 weeks , 2nd Phase – 8 week",
          "1st Phase- 4 weeks , 2nd Phase – 8 week",
          "1st Phase- 4 weeks , 2nd Phase – 8 week",
          "1st Phase- 4 weeks , 2nd Phase – 8 week",
        ],
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
        ],
        ["Balance Based Drawdown", true, true, true, true, true, true],
        [
          "Minimum Trading Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
        ],
        ["Profit Split Upto", "95%", "95%", "95%", "95%", "95%", "95%"],
        ["News Trading", true, true, true, true, true, true],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
    {
      headerData2: [
        { label: "Account Size" },
        { label: "$6000", price: "fee: $59" },
        { label: "$15,000", price: "fee: $119" },
        { label: "$25,000", price: "fee: $249" },
        { label: "$50,000", price: "fee: $349" },
        { label: "$100,000", price: "fee: $549" },
        { label: "$200,000", price: "fee: $999" },
      ],
      step1Data: [
        [
          "Profit Target",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
        ],
        [
          "Time",
          "4 weeks",
          "4 weeks",
          "4 weeks",
          "4 weeks",
          "4 weeks",
          "4 weeks",
        ],
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1250)",
          "5% ($2500)",
          "5% ($5000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1500)",
          "10% ($2500)",
          "10% ($5000)",
          "10% ($10,000)",
          "10% ($20,000)",
        ],
        ["Balance Based Drawdown", true, true, true, true, true ,true],
        [
          "Minimum Trading Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
        ],
        ["Profit Split Upto", "95%", "95%", "95%", "95%", "95%","95%"],
        ["News Trading", true, true, true, true, true,true],
        ["First Reward", "Monthly", "Monthly", "Monthly", "Monthly", "Monthly","Monthly"],
      ],
    },
    {
      headerData2: [
        { label: "Account Size" },
        { label: "$6,000", price: "fee: $79" },
        { label: "$15,000", price: "fee: $129" },
        { label: "$25,000", price: "fee: $259" },
        { label: "$50,000", price: "fee: $359" },
        { label: "$100,000", price: "fee: $559" },
        { label: "$200,000", price: "fee: $999" },
      ],
      step1Data: [
        [
          "Profit Target",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
        ],

        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
        ],
        ["Balance Based Drawdown", true, true, true, true, true, true],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        ["Profit Split Upto", "95%", "95%", "95%", "95%", "95%", "95%"],
        ["News Trading", true, true, true, true, true, true],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
    {
      headerData2: [
        { label: "Account Size" },
        { label: "$5,000" },
        { label: "$10,000", },
        { label: "$25,000", },
        { label: "$50,000", },
        { label: "$100,000", },
      ],
      headerData: [
        { label: "Account Size" },
        { label: "$6,000", price: "fee: $59" },
        { label: "$15,000", price: "fee: $119" },
        { label: "$25,000", price: "fee: $229" },
        { label: "$50,000", price: "fee: $379" },
        { label: "$100,000", price: "fee: $699" },
      ],
      step1Data: [
        ["Profit Target", "0%", "0%", "0%", "0%", "0%"],
        [
          "Maximum Daily Loss",
          "4% ($200)",
          "4% ($400)",
          "4% ($1000)",
          "4% ($2000)",
          "4% ($4000)",
        ],
        [
          "Maximum Overall Loss",
          "8% ($400)",
          "8% ($800)",
          "8% ($2000)",
          "8% ($4000)",
          "8% ($8000)",

        ],
        ["Balance Based Drawdown", true, true, true, true, true],
        [
          "Minimum Trading Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
        ],
        ["News Trading", false, false, false, false, false],
        [
          "First Reward",
          "0 Days",
          "0 Days",
          "0 Days",
          "0 Days",
          "0 Days",
        ],
      ],
      step2Data: [
        ["Profit Target", "25%", "25%", "25%", "25%", "25%"],
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
        ],
        ["Balance Based Drawdown", true, true, true, true, true],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        ["Profit Split Upto", "95%", "95%", "95%", "95%", "95%"],
        ["News Trading", false, false, false, false, false],
        ["First Reward", "Monthly", "Monthly", "Monthly", "Monthly", "Monthly"],
      ],
    },
  ];
  const handleCardClick = (tabIndex, packageId) => {
    setActiveTab(tabIndex);
    setActivePackage(packageId);
    setSelectedStep("step-2");
    tabsSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
    setSelectedStep("step-2");
  };

  return (
    <>
      <section className="section_padding package_bg pb-0" id="service">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-9">
              <div className="text-center">
                <h2 className="heading_h2">
                  Our Offering
                </h2>
                <p>
                  Our offerings provide traders with a range of options to suit different trading styles and experience levels, from the more challenging Aizelle Lite to the streamlined Aizelle Pro and the consistency-focused Aizelle Express. The demo accounts allow traders to practice and refine their strategies before committing to a funded account.
                </p>
              </div>
            </div>
          </div>
          <div ref={tabsSectionRef} className="packages_section">
            <div className="row g-md-4 g-2 blur_card flex-nowrap mt-lg-5 mt-5">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7">
                <div
                  className={`package_card_container h-100 ${activePackage === 1 ? "active-popular" : ""
                    }`}
                >
                  {activePackage === 1 && (
                    <div className="popularplan">popular plan</div>
                  )}
                  <div
                    className="package_card package mx-2 h-100"
                    onClick={() => handleCardClick(1, 1)}
                  >
                    <div className="package_back1 position-relative">
                      <div className="mb-xl-4 mb-3">
                        <img
                          src="assets/images/new_home/Light White.svg"
                          alt="Stellar"
                          className="img-fluid"
                        />
                      </div>
                      <h2 className="sub_title">Aizelle lite</h2>
                      <p className="sub_para mb-xl-4 mb-3">
                        The most popular package amongst Aizelle traders
                      </p>
                      <hr className="mb-xl-4 mb-3" />
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          2-step evaluation process
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Account sizes: $6,000 to $200,000
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Profit target: 1ST Phase 8%, 2ND Phase 5%
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Time: 1st Phase- 4 Weeks, 2nd Phase- 8 Weeks
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Maximum daily loss: 5% of account size
                        </p>
                      </div>

                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Maximum overall loss: 10% of account size
                        </p>
                      </div>

                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Balance-based drawdown
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Minimum trading days: 5
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Profit split up to 95%
                        </p>
                      </div>
                      <div className="d-flex gap-3 mb-lg-3 mb-2">
                        <img src="assets/images/new_home/true.svg" alt="" />
                        <p className="sub_para mb-0">
                          Monthly rewards
                        </p>
                      </div>


                    </div>
                    <div className="hover-text">Start Challenge</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7">
                <div
                  className={`package_card_container h-100 ${activePackage === 2 ? "active-popular" : ""
                    }`}
                >
                  {activePackage === 2 && (
                    <div className="popularplan">popular plan</div>
                  )}
                  <div
                    className="package_card pacakge_back2 mx-2 mb-2 mt-2 h-100"
                    onClick={() => handleCardClick(2, 2)}
                  >
                    <div className="mb-xl-4 mb-3">
                      <img
                        src="assets/images/new_home/Pro White.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>

                    <h2 className="sub_title text-align-start">Aizelle pro</h2>
                    <p className="sub_para mb-xl-4 mb-3">
                      The most affordable package for new and upcoming traders
                    </p>
                    <hr className="mb-xl-4 mb-3" />
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        1-step evaluation process
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Account sizes: $6,000 to $200,000
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Profit target: 10%
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Time : 1 Month
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Maximum daily loss: 5% of account size
                      </p>
                    </div>

                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Maximum overall loss: 10% of account size
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Balance-based drawdown
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Minimum trading days: 5
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Profit split up to 95%
                      </p>
                    </div>

                    <div className="d-flex gap-3 mb-lg-3 mb-4">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Monthly rewards
                      </p>
                    </div>

                  </div>
                  <div className="hover-text">Start Challenge</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7">
                <div
                  className={`package_card_container h-100 ${activePackage === 3 ? "active-popular" : ""
                    }`}
                >
                  {activePackage === 3 && (
                    <div className="popularplan">popular plan</div>
                  )}
                  <div
                    className="package_card pacakge_back3 mx-2 mb-2 mt-2 h-100"
                    onClick={() => handleCardClick(3, 3)}
                  >
                    <div className="mb-xl-4 mb-3">
                      <img
                        src="assets/images/new_home/Max White.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>


                    <h2 className="sub_title">Aizelle Express</h2>
                    <p className="sub_para mb-xl-4 mb-3">
                      The only package that offers free retakes
                    </p>
                    <hr className="mb-xl-4 mb-3" />
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Consistency-focused evaluation
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Account sizes: $6,000 to $200,000
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Profit target: 20% across all account sizes
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Maximum daily loss: 5% of account size
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Maximum overall loss: 10% of account size
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Balance-based drawdown
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Minimum trading days: 10
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Profit split up to 95%
                      </p>
                    </div>

                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Monthly rewards
                      </p>
                    </div>
                  </div>
                  <div className="hover-text">Start Challenge</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-7">
                <div
                  className={`package_card_container  h-100 ${activePackage === 4 ? "active-popular" : ""
                    }`}
                >
                  {activePackage === 4 && (
                    <div className="popularplan">popular plan</div>
                  )}
                  <div
                    className="package_card pacakge_back4 mx-2 mb-2 mt-2 h-100"
                    onClick={() => handleCardClick(4, 4)}
                  >
                    <div className="mb-xl-4 mb-3">
                      <img
                        src="assets/images/new_home/Express White.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h2 className="sub_title">Aizelle Demo Accounts</h2>
                    <p className="sub_para mb-xl-4 mb-3">
                      The package offers profit shares without needing 5% growth
                    </p>
                    <hr className="mb-xl-4 mb-3" />
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Account sizes: $5,000 to $100,000
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Maximum daily loss: 4% of account size
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Maximum overall loss: 8% of account size
                      </p>
                    </div>
                    <div className="d-flex gap-3 mb-lg-3 mb-2">
                      <img src="assets/images/new_home/true.svg" alt="" />
                      <p className="sub_para mb-0">
                        Minimum trading days: 5
                      </p>
                    </div>
                  </div>
                  <div className="hover-text">Start Challenge</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentSwiper />
        <section className="section_padding overflow-hidden pb-0">
          <div className="purple_bg" ref={tabsSectionRef}>
            <div className="py-5">
              <div className="container">
                <div className="col-12">
                  <div className="table_heading mb-4 text-center mt-xl-5 mt-lg-4 mt-0 pt-md-5 pt-0">
                    <h1 className="heading_h2 text-white">
                      Choose the Best Plan
                    </h1>
                  </div>
                </div>
                <div
                  className="nav_tabs d-flex justify-content-center align-items-center mb-3 
                overflow-hidden rounded_220"
                >
                  <div className="tab_nav">
                    {tabsData.map((tab, index) => (
                      <button
                        key={index}
                        className={`tab-button tab_btn tab_remove ${activeTab === index + 1 ? "active" : ""
                          }`}
                        onClick={() => handleTabClick(index + 1)}
                      >
                        <img
                          src={
                            activeTab === index + 1
                              ?
                              index === 0
                                ? "assets/images/new_home/Light Color.svg"
                                : index === 1
                                  ? "assets/images/new_home/Pro Color.svg"
                                  : index === 2
                                    ? "assets/images/new_home/MAx Color.svg"
                                    : "assets/images/new_home/Express Color.svg"
                              :
                              index === 0
                                ? "assets/images/new_home/Light White.svg"
                                : index === 1
                                  ? "assets/images/new_home/Pro White.svg"
                                  : index === 2
                                    ? "assets/images/new_home/Max White.svg"
                                    : "assets/images/new_home/Express White.svg"
                          }
                          alt={`icons`}
                          className="tab-icon"
                        />
                        {index === 0
                          ? "Aizelle lite"
                          : index === 1
                            ? "Aizelle pro"
                            : index === 2
                              ? "Aizelle Express"
                              : "Aizelle demo"}
                      </button>
                    ))}
                  </div>
                </div>
                {/* {activeTab === 1 ? (
                  <div className="d-md-flex justify-content-center align-items-center gap-4 mb-md-5 mb-4 sub_para text-white">
                    <div className="text-md-start text-center mb-md-0 mb-2">
                      <span>Select Preference</span>
                    </div>
                    <div className="d-flex justify-content-center gap-3">
                      <div className="d-flex gap-2">
                        <input
                          type="radio"
                          name="preference"
                          id="step-2"
                          checked={selectedStep === "step-2"}
                          onChange={() => setSelectedStep("step-2")}
                        />
                        <label htmlFor="step-2">2-step</label>
                      </div>
                      <div className="d-flex gap-2">
                        <input
                          type="radio"
                          name="preference"
                          id="step-1"
                          checked={selectedStep === "step-1"}
                          onChange={() => setSelectedStep("step-1")}
                        />
                        <label htmlFor="step-1">1-step</label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}

                <div className="table_round table-responsive">
                  {tabsData?.map(
                    (tab, index) =>
                      activeTab === index + 1 && (
                        <table className="pricing-table" key={index}>
                          <thead>
                            <tr className="sub_para text-wrap">
                              {(selectedStep === "step-2"
                                ? tab.headerData2
                                : tab.headerData
                              )?.map((header, index) => (
                                <th
                                  key={index}
                                  className="heading_h5 fw-normal"
                                >
                                  {header.label}
                                  <button className="get_plan_btn border-0 mt-3 font_14">
                                    Get Plan <br />
                                    <span>{header.price}</span>
                                  </button>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedStep === "step-2"
                              ? tab.step1Data?.map((row, rowIndex) => (
                                <tr className="sub_para" key={rowIndex}>
                                  {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>
                                      {cell === true ? (
                                        <img
                                          src="assets/images/new_home/correct.svg"
                                          alt="Correct"
                                        />
                                      ) : cell === false ? (
                                        <img
                                          src="assets/images/new_home/cross.svg"
                                          alt="Cross"
                                        />
                                      ) : (
                                        cell
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                              : tab.step2Data?.map((row, rowIndex) => (
                                <tr className="sub_para" key={rowIndex}>
                                  {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>
                                      {cell === true ? (
                                        <img
                                          src="assets/images/new_home/correct.svg"
                                          alt="Correct"
                                        />
                                      ) : cell === false ? (
                                        <img
                                          src="assets/images/new_home/cross.svg"
                                          alt="Cross"
                                        />
                                      ) : (
                                        cell
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )
                  )}
                </div>
                {/* <div className="table_footer mt-xl-5 mt-md-4 mt-3 mb-4 d-flex justify-content-center flex-wrap text-white sub_para">
                  <p className="add_exp fw-bold mb-2">Add-ons for Express</p>
                  <div className="table-item d-flex flex-wrap justify-content-center">
                    <li className="faq_pay">Lifetime Payout 95%</li>
                    <li className="faq_pay">150% Reward</li>
                    <li className="faq_pay">Double Up</li>
                    <li className="faq_pay">No Minimum Trading Days</li>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
