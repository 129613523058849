import Header from "../component/Header";
import Footer from "../component/Footer";
export default function Freecompetition() {
    return (
        <>
            <Header />
            <section className="section_padding">
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <img src="assets/images/new_home/cup.avif" alt="" className="img-fluid" />
                    </div>
                    <div className="text-center">
                        <h2 className="heading_h2 text-uppercase fw-bold">Comming soon...</h2>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}