import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function TradeHistory() {
    return (
        <>

            <Header />
            <div className="trade_history_hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="fw-bolder text-light">
                                Trade History
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            {/* <img src="assets/images/home/trade.png" alt="" className="w-50" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div className="section_padding pb-0">
                        <div className="row g-3">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="trade_card">
                                    <div>
                                        <div className="rounded-circle p-2 bg-light d-inline-block">
                                            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22 8.5C22 12.09 19.09 15 15.5 15C15.33 15 15.15 14.99 14.98 14.98C14.73 11.81 12.19 9.26999 9.01999 9.01999C9.00999 8.84999 9 8.67 9 8.5C9 4.91 11.91 2 15.5 2C19.09 2 22 4.91 22 8.5Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15 15.5C15 19.09 12.09 22 8.5 22C4.91 22 2 19.09 2 15.5C2 11.91 4.91 9 8.5 9C8.67 9 8.84999 9.00999 9.01999 9.01999C12.19 9.26999 14.73 11.81 14.98 14.98C14.99 15.15 15 15.33 15 15.5Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M5.59 2H3C2.45 2 2 2.45 2 3V5.59C2 6.48 3.07999 6.92999 3.70999 6.29999L6.29999 3.70999C6.91999 3.07999 6.48 2 5.59 2Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path opacity="0.4" d="M18.4097 22.0003H20.9997C21.5497 22.0003 21.9997 21.5503 21.9997 21.0003V18.4103C21.9997 17.5203 20.9197 17.0703 20.2897 17.7003L17.6997 20.2903C17.0797 20.9203 17.5197 22.0003 18.4097 22.0003Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="mt-5 d-flex justify-content-between">
                                        <div className="pt-3">
                                            <p className="mb-1">
                                                No Of Trade
                                            </p>
                                            <h3 className="mb-0">
                                                0
                                            </h3>
                                        </div>
                                        <img src="assets/images/home/chatbot.gif" alt="" className="w-25" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="trade_card">
                                    <div>
                                        <div className="rounded-circle p-2 bg-light d-inline-block">
                                            <svg fill="#000000" width="30px" height="30px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="10. Growth" id="_10._Growth">
                                                    <path d="M17,12.05V11h3a5,5,0,0,0,5-5V4a1,1,0,0,0-1-1H20a4.92,4.92,0,0,0-3,1V1a1,1,0,0,0-2,0V2a4.92,4.92,0,0,0-3-1H8A1,1,0,0,0,7,2V4a5,5,0,0,0,5,5h3v3.05a10,10,0,1,0,2,0Zm3-7h3V6a3,3,0,0,1-3,3H17V8A3,3,0,0,1,20,5ZM9,4V3h3a3,3,0,0,1,3,3V7H12A3,3,0,0,1,9,4Zm7,26a8,8,0,1,1,8-8A8,8,0,0,1,16,30Z" />
                                                    <path d="M16,19h2a1,1,0,0,0,0-2H17a1,1,0,0,0-2,0v.18A3,3,0,0,0,16,23a1,1,0,0,1,0,2H14a1,1,0,0,0,0,2h1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,16,21a1,1,0,0,1,0-2Z" />
                                                    <path d="M5.71,7.29l-2-2a1,1,0,0,0-1.42,0l-2,2A1,1,0,0,0,1.71,8.71L2,8.41V11a1,1,0,0,0,2,0V8.41l.29.3a1,1,0,0,0,1.42,0A1,1,0,0,0,5.71,7.29Z" />
                                                    <path d="M31.71,13.29l-2-2a1,1,0,0,0-1.42,0l-2,2a1,1,0,0,0,1.42,1.42l.29-.3V17a1,1,0,0,0,2,0V14.41l.29.3a1,1,0,0,0,1.42,0A1,1,0,0,0,31.71,13.29Z" />
                                                </g>
                                            </svg>


                                        </div>
                                    </div>
                                    <div className="mt-5 d-flex justify-content-between">
                                        <div className="pt-3">
                                            <p className="mb-1">
                                                Total No Of Profit Trade
                                            </p>
                                            <h3 className="mb-0">
                                                0
                                            </h3>
                                        </div>
                                        <img src="assets/images/home/chatbot.gif" alt="" className="w-25" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="trade_card">
                                    <div>
                                        <div className="rounded-circle p-2 bg-light d-inline-block">
                                            <svg fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve">
                                                <g>
                                                    <g>
                                                        <path d="M502.317,182.775c-5.352,0-9.683,4.335-9.683,9.683v58.864L314.82,126.429c-2.913-2.038-6.402-2.326-9.579-0.728
			l-148.313,74.232L16.699,50.277c-3.659-3.901-9.749-4.099-13.645-0.44c-3.905,3.655-4.085,9.787-0.426,13.687l145.25,154.923
			c2.931,3.13,7.579,3.943,11.399,2.038l149.763-74.279L486.645,271.13h-70.261c-5.352,0-9.683,4.335-9.683,9.683
			c0,5.347,4.331,9.683,9.683,9.683h87.144c5.352,0,8.472-5.546,8.472-10.893v-87.144C512,187.111,507.669,182.775,502.317,182.775z
			" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M382.419,281.229l-86.842-58.096c-2.884-1.924-6.43-2.161-9.55-0.605l-149.032,74.554L64.46,233.586
			c-2.846-2.492-7.508-3.097-10.959-1.532c-3.461,1.57-6.269,5.016-6.269,8.818V453.89c0,5.347,5.541,10.893,10.893,10.893h319.529
			c5.352,0,8.472-5.546,8.472-10.893V289.285C386.125,286.046,385.113,283.025,382.419,281.229z M366.76,445.418H66.597v-183.21
			l62.011,53.727c2.95,2.595,7.508,3.125,11.007,1.376l149.438-74.951l77.707,52.106V445.418z" />
                                                    </g>
                                                </g>
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="mt-5 d-flex justify-content-between">
                                        <div className="pt-3">
                                            <p className="mb-1">
                                                Total No Of Loss Trade
                                            </p>
                                            <h3 className="mb-0">
                                                0
                                            </h3>
                                        </div>
                                        <img src="assets/images/home/chatbot.gif" alt="" className="w-25" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="trade_card">
                                    <div>
                                        <div className="rounded-circle p-2 bg-light d-inline-block">
                                            <svg width="30px" height="30px" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><defs><style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.91px;}" }} /></defs><path className="cls-1" d="M12,12H22.5a10.45,10.45,0,0,1-3.07,7.42L12,12V1.48A10.5,10.5,0,1,0,19.43,19.4" /><path className="cls-1" d="M15.82,8.16h3.34a1.43,1.43,0,0,0,1.43-1.43h0A1.43,1.43,0,0,0,19.16,5.3h-1a1.44,1.44,0,0,1-1.43-1.44h0A1.43,1.43,0,0,1,18.2,2.43h3.35" /><line className="cls-1" x1="18.68" y1="0.52" x2="18.68" y2="2.43" /><line className="cls-1" x1="18.68" y1="8.16" x2="18.68" y2="10.07" /></svg>
                                        </div>
                                    </div>
                                    <div className="mt-5 d-flex justify-content-between">
                                        <div className="pt-3">
                                            <p className="mb-1">
                                                Total Profit Per
                                            </p>
                                            <h3 className="mb-0">
                                                0 %
                                            </h3>
                                        </div>
                                        <img src="assets/images/home/chatbot.gif" alt="" className="w-25" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-12">
                        <div className="section_padding">
                            <h2 className="fw-bolder mb-4 mb-1">
                                Trade Transtion List
                            </h2>
                            <div className="table-responsive overflow-scroll">
                                <table className="table trade_table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr No</th>
                                            <th scope="col">Symbol</th>
                                            <th scope="col">Buy Price</th>
                                            <th scope="col">Sell Price</th>
                                            <th scope="col">PNL Per.</th>
                                            <th scope="col">Trade Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}