import React, { useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import ScrollTo from "react-scroll-to-top";
import axios from "axios";
import Blog from "../component/Blog";
import Platform from "../component/Platform";
import PricingTable from "../component/PackageItems";
import PackageItems from "../component/PackageItems";
import PaymentSwiper from "../component/PaymentSwiper";
export default function Home() {
  const [close, setClose] = useState(true);

  const [input, setInput] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [verror, setVerror] = useState("");
  const [validations, setValidations] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });

  const validationRules = {
    name: "Name is required",
    email: "Email is required",
    mobile: "Mobile is required",
    message: "Message is requied",
  };

  const handelInputs = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
    setValidations({ ...validations, [name]: false });
  };
  const handelubmit = () => {
    for (const field in input) {
      if (!input[field]) {
        setValidations({ ...validations, [field]: true });
        setVerror(validationRules[field]);
        return;
      }
      const MOBILE_REGX = /^[0-9]{10}$/;
      const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (field === "email" && !EMAIL_REGEX.test(input.email)) {
        setValidations({ ...validations, email: true });
        setVerror("Valid Email Required.");
        return;
      }
      // if (field === "mobile") {
      //   setValidations({ ...validations, mobile: true });
      //   setVerror("Valid Mobile Required.");
      //   return;
      // }
    }
    Addapi();
  };
  const Addapi = async () => {
    const bodyData = {};
    bodyData.email = input.email;
    bodyData.name = input.name;
    bodyData.mobile = input.mobile;
    bodyData.message = input.message;
    const FormData = require("form-data");
    let data = new FormData();
    data.append("api_key", "7567-67403d8d60895-00010");
    data.append("to", "info@aizelle.com");
    data.append("subject", "Subeject");
    data.append(
      "message",
      `Name : ${input.name}, <br/> email : ${input.email} <br/> mobile : ${input.mobile} <br/> message: ${input.message}`
    );

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://smtp1.mailmantra.com/api/mail/v1",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setInput({
          ...input,
          email: "",
          name: "",
          mobile: "",
          message: "",
        });
      })
      .catch((error) => {
        // console.log(error);
        setInput({
          ...input,
          email: "",
          name: "",
          mobile: "",
          message: "",
        });
      });
  };

  return (
    <>
      <ScrollToTop>
        <Header />

        <section className="aizelle_bg pb-lg-0 overflow-hidden">
          <div className="container">
            <div className="row align-items-center g-lg-5 g-4">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="">
                  <h1 className=" heading_h1 text-light index_2">
                    Forex Training & Funding
                    {/* <br className="d-none d-lg-block" /> */}
                  </h1>
                  <p className="sub_para index_2 text-light">
                    Practical Forex Training & Funding For Traders
                  </p>
                  {/* <p className="sub_para mb-xl-4">
                    We merge cutting-edge technology with deep market knowledge to
                    create a trading platform that empowers you to succeed.
                  </p> */}
                  <div className="d-flex gap-2 index_2">
                    <a
                      className="btn aizelle_btn sub_para"
                      target="_blank"
                      href="https://prop.aizelle.org/register"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <img
                  src="assets/images/home/Comp.gif"
                  alt=""
                  className="img-fluid index_2"
                />
                {/* <div className="hero_img">
                  <img
                    src="assets/images/home/ilustrator.png"
                    alt="Illustrator"
                    className="img-fluid aizelle_hero"
                  />
                  <img
                    src="assets/images/home/hero.png"
                    alt="Hero"
                    className="img-fluid position-relative robot_hero"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="section_padding" id="about">
          <div className="container">
            <div className="row g-lg-5 g-4 align-items-center">
              <div className="col-lg-6">
                <img
                  src="assets/images/home/about_aizelle.png"
                  alt="about Us"
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-lg-6">
                {/* <span className="sub_heading mb-xl-2 mb-1">About</span> */}
                <h2 className="heading_h2 mb-xl-4 mb-sm-2 mb-1">
                  About  Aizelle
                </h2>
                <p className="sub_para mb-xl-4">
                  Aizelle is a dynamic proprietary trading firm that empowers traders to reach new heights in the financial markets. We provide our traders with substantial capital, cutting-edge technology, and a supportive environment to maximize their potential. Our innovative approach includes a no-time-limit challenge, allowing traders to prove their skills at their own pace.
                </p>

                <p className="sub_para d-flex align-items-center mb-xl-3 mb-md-2 mb-1">
                  <div>
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M7 12L9.89075 14.8907V14.8907C9.95114 14.951 10.049 14.9511 10.1094 14.8907V14.8907L17 8"
                          stroke="#60c784"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />{" "}
                      </g>
                    </svg>
                  </div>
                  With a commitment to rapid payouts and profit splits of up to 90%, Aizelle stands out in the competitive prop trading landscape
                </p>
                <p className="sub_para  d-flex align-items-center mb-xl-3 mb-md-2 mb-1">
                  <div>
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M7 12L9.89075 14.8907V14.8907C9.95114 14.951 10.049 14.9511 10.1094 14.8907V14.8907L17 8"
                          stroke="#60c784"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                  We offer a range of account sizes to accommodate both novice and experienced traders, fostering a diverse community of financial professionals
                </p>
                <p className="sub_para d-flex align-items-center mb-xl-3 mb-md-2 mb-1">
                  <div>
                    <svg
                      width="30px"
                      height="30px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M7 12L9.89075 14.8907V14.8907C9.95114 14.951 10.049 14.9511 10.1094 14.8907V14.8907L17 8"
                          stroke="#60c784"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />{" "}
                      </g>
                    </svg>
                  </div>
                  At Aizelle, we believe in aligning our success with that of our traders, creating a symbiotic relationship that drives mutual growth and prosperity in the ever-evolving world of proprietary trading.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section_padding" id="about">
          <div className="container">
            <div className="row g-lg-5 g-4 align-items-center">

              <div className="col-lg-6">
                {/* <span className="sub_heading mb-xl-2 mb-1">
                  MISSION & VISION
                  </span> */}
                <h2 className="heading_h2 mb-xl-4 mb-sm-2 mb-1">
                  Our Mission & Vision
                </h2>
                <p className="sub_para mb-xl-4">
                  Aizelle envisions becoming a global
                  leader in proprietary trading, recognized
                  for our innovative approach, trader-centric ethos, and commitment to excellence. We aim to:
                </p>
                <p>
                  <ul>
                    <li>
                      1.Revolutionize the prop trading landscape by offering unparalleled resources and support to traders at all levels of experience.
                    </li>
                    <li>
                      2.Create a diverse and inclusive trading community that thrives on collaboration, continuous learning, and mutual success.
                    </li>
                    <li>
                      3.Set new industry benchmarks for transparency, fairness, and rapid payouts, ensuring our traders' success aligns seamlessly with our own.
                    </li>
                    <li>
                      4.Leverage cutting-edge technology to provide our traders with a competitive edge in the ever-evolving financial markets.
                    </li>
                    <li>
                      5.Cultivate a culture of integrity, innovation, and excellence that inspires the next generation of financial leaders.
                    </li>
                  </ul>
                </p>
                <p>
                  Through our unwavering dedication to these principles, Aizelle strives to transform the world of proprietary trading, one successful trader at a time.
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src="assets/images/home/mission_vision.png"
                  alt="about Us"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </section>


        {/* <section className="section_padding">
          <div className="container">
            <div className="row g-lg-5 g-4">
              <div className="col-12">
                <h2 className="heading_h2 mb-xl-4 text-center">ASSETS</h2>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="assets_card text-center">
                  <img
                    src="assets/images/new_home/crypto.webp"
                    alt=""
                    className="img-fluid"
                  />
                  <h6 className="heading_h6 mt-3 fw-bold">Forex</h6>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="assets_card text-center">
                  <img
                    src="assets/images/new_home/forex.webp"
                    alt=""
                    className="img-fluid"
                  />
                  <h6 className="heading_h6 mt-3 fw-bold">BLOCKCHAIN</h6>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="assets_card text-center">
                  <img
                    src="assets/images/new_home/mutualfund.webp"
                    alt=""
                    className="img-fluid"
                  />
                  <h6 className="heading_h6 mt-3 fw-bold">STOCK'S</h6>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="assets_card text-center">
                  <img
                    src="assets/images/new_home/stock.webp"
                    alt=""
                    className="img-fluid"
                  />
                  <h6 className="heading_h6 mt-3 fw-bold">MUTUAL FUND</h6>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section_padding text-center">
          <div className="container">
            <div className="row g-xl-4 g-3">
              <div className="col-12 mb-xl-4 mb-2">
                <h2 className="heading_h2 text-center">
                  Empowering Promising <br /> Traders Worldwide
                </h2>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="content">
                  <img
                    src="assets/images/new_home/trader1.svg"
                    className="mb-xl-3 mb-2"
                    alt="trader1"
                  />
                  <h2 className="heading_h6">Up to 95%</h2>
                  <p className="sub_para">of profit Split</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="content">
                  <img
                    src="assets/images/new_home/trader2.svg"
                    className="mb-xl-3 mb-2"
                    alt="trader2"
                  />
                  <h2 className="heading_h6">Up to $300k</h2>
                  <p className="sub_para">Trading Accounts</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="content">
                  <img
                    src="assets/images/new_home/trader3.svg"
                    className="mb-xl-3 mb-2"
                    alt="trader3"
                  />
                  <h2 className="heading_h6">Add-Ons</h2>
                  <p className="sub_para">to Enhance Trading</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="content">
                  <img
                    src="assets/images/new_home/trader4.svg"
                    className="mb-xl-3 mb-2"
                    alt="trader4"
                  />
                  <h2 className="heading_h6">No time limit</h2>
                  <p className="sub_para">in Challenge Phase</p>
                </div>
              </div>
              <div className="col-12 text-center">
                <div
                  className="d-flex gap-xl-3 gap-2 index_2 mt-xl-4 mt-3
                align-items-center justify-content-center"
                >
                  <a
                    className="btn aizelle_btn sub_para px-md-4 px-3 text-nowrap"
                    href="https://prop.aizelle.org/register"
                  >
                    Strat Challenge
                  </a>
                  <a
                    className="btn aizelle_btn sub_para px-md-5 px-4 text-nowrap"
                    href="https://prop.aizelle.org/login"
                  >
                    Free Trial
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section_padding pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img
                  src="assets/images/new_home/about.webp"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6">
                <div>
                  <h2 className="heading_h2 mb-lg-4 mb-3">
                    WHERE BEGINNERS BECOME EXPERTS
                  </h2>
                  <p className="sub_para">
                    At aizelle Trading Academy, we are your dedicated partners
                    committed to guiding individuals from complete beginners to
                    proficient Forex traders. Our mission is to empower you with
                    comprehensive knowledge and practical skills, covering
                    everything from the fundamentals of Forex trading to
                    advanced strategies and risk management. Our experienced
                    instructors bring a wealth of knowledge to ensure you gain
                    insights beyond the basics. What sets us apart is our
                    holistic learning approach, integrating theoretical
                    understanding with hands-on exercises for real-world
                    application. By the end of our courses, you will possess the
                    knowledge and skills to confidently navigate the global
                    currency market. At aizelle, we are more than an academy.
                  </p>
                  <button className="nav-link menu_padding btn aizelle_btn">
                    Read More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="section_padding">
          <div className="container">
            <div className="row g-xl-5 g-4">
              <div className="col-lg-6">
                <div className="mission_card why_choose">
                  <div className="d-flex gap-3 align-items-center mb-xl-4 mb-3">
                    <img src="assets/images/new_home/mission.svg" alt="" />
                    <h6 className="heading_h6 mb-0">Our Mission</h6>
                  </div>
                  <p className="sub_para">
                    {" "}
                    At aizelle Trading Academy, we are your dedicated partners
                    committed to guiding individuals from complete beginners to
                    proficient Forex traders. Our mission is to empower you with
                    comprehensive knowledge and practical skills, covering
                    everything from the fundamentals of Forex trading to
                    advanced strategies and risk management.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mission_card why_choose">
                  <div className="d-flex gap-3 align-items-center mb-xl-4 mb-3">
                    <img src="assets/images/new_home/vision.svg" alt="" />
                    <h6 className="heading_h6 mb-0">Our Vision</h6>
                  </div>
                  <p className="sub_para">
                    {" "}
                    Our experienced instructors bring a wealth of knowledge to
                    ensure you gain insights beyond the basics. What sets us
                    apart is our holistic learning approach, integrating
                    theoretical understanding with hands-on exercises for
                    real-world application. By the end of our courses, you will
                    possess the knowledge and skills to confidently navigate the
                    global currency market. At aizelle, we are more than an
                    academy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section_padding pb-0" id="whyus">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-4">
                <div className="d-lg-none d-flex mb-0 align-items-center justify-content-center">
                  <h2 className="heading_h2">Why Choose Aizelle</h2>
                </div>
                <div className="yellow_card d-lg-flex d-none align-items-center justify-content-center why_choose_card">
                  <h2 className="heading_h2">Why Choose Aizelle</h2>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="yellow_card why_choose_card">
                  <div className="row align-items-center">
                    <div className="col-md-7 order-2 order-md-1">
                      <h2 className="heading_h2 mb-xl-4 mb-3">
                        Guaranteed Payouts
                      </h2>
                      <p className="sub_para">
                        Get paid in 24 hours or we pay $1,000 extra.
                      </p>
                    </div>
                    <div className="col-md-5 order-1 order-md-2 d-flex justify-content-center">
                      <img
                        src="assets/images/new_home/Payouts.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="yellow_card why_choose">
                  <div className="row align-items-center">
                    <div className="col-md-6 order-2 order-lg-1">
                      <h2 className="heading_h2 mb-xl-4 mb-3">24/7 Support</h2>
                      <p className="sub_para">
                        Aizelle Academy provides 24/7 support for continuous
                        assistance with course material, trading concepts, and
                        technical queries. We’re here for you every step of the
                        way.
                      </p>
                    </div>
                    <div className="col-md-6 order-1 order-lg-2 d-flex justify-content-center">
                      <img
                        src="assets/images/new_home/SUPPORT.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="yellow_card why_choose">
                  <div className="row align-items-center">
                    <div className="col-md-6 order-2 order-md-1">
                      <h2 className="heading_h2 mb-xl-4 mb-3">
                        Best Trading Conditions
                      </h2>
                      <p className="sub_para">
                        Transforming trading journeys globally through
                        industry-leading resources
                      </p>
                    </div>
                    <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center">
                      <img
                        src="assets/images/new_home/Conditions.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_padding text-dark">
          <div className="container">
            <div className="row g-lg-5 g-4 mt-md-3">
              <div className="col-lg-3 text-lg-start text-center">
                <h1 className="heading_h2 mb-xl-4 mb-3">️Key Highlights</h1>
                <p className="sub_para">
                  Power up your trading{" "}
                  <br className="d-md-none d-lg-block d-none" /> success with{" "}
                  <br className="d-md-none d-block" /> Aizelle & get{" "}
                  <br className="d-lg-none d-md-block d-none" /> maximum profit.
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <img src="assets/images/new_home/key1.svg" alt="" />
                <h2 className="heading_h6 fw-semibold my-md-4 my-3">
                  Upto 95% Profit Share in Challenge Phase
                </h2>
                <p className="sub_para">
                  The only trading prop firm that offers a upto 95% profit share
                  from the Challenge Phase profits.
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <img src="assets/images/new_home/key2.svg" alt="" />
                <h2 className="heading_h6 fw-semibold my-md-4 my-3">
                  Balance-Based Drawdown
                </h2>
                <p className="sub_para">
                  Enjoy stress-free trading with Aizelle’s Balance-Based
                  Drawdown, available across all account types.
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <img src="assets/images/new_home/key3.svg" alt="" />
                <h2 className="heading_h6 fw-semibold my-md-4 my-3">
                  Competitive Spreads & High Leverage
                </h2>
                <p className="sub_para">
                  With spreads starting from 0.0 pips and a leverage of up to
                  1:100, Aizelle gives traders an unmatched trading experience.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 d-lg-block d-none">
                <h3 className="heading_h5 fw-semibold mb-md-3 mt-xl-4 mt-0 mb-3 more_sec text-uppercase">
                  There is more -
                </h3>
                <ul className="p-0 ali_low list-unstyled sub_para">
                  <li className="d-flex gap-2 mt-2">
                    <img
                      src="assets/images/new_home/low.svg"
                      alt="low"
                      className="ali_row"
                    />
                    Low commissions
                  </li>
                  <li className="d-flex gap-2 mt-2">
                    <img
                      src="assets/images/new_home/row.svg"
                      alt="row"
                      className="ali_row me-2"
                    />
                    Raw spreads
                  </li>
                  <li className="d-flex gap-2 mt-2">
                    <img
                      src="assets/images/new_home/best.svg"
                      alt="best"
                      className="ali_row"
                    />
                    Best packages
                  </li>
                  <li className="d-flex gap-2 mt-2">
                    <img
                      src="assets/images/new_home/Affordable.svg"
                      alt="Affordable"
                      className="ali_row"
                    />
                    Affordable
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6">
                <img src="assets/images/new_home/key3.svg" alt="" />
                <h2 className="heading_h6 fw-semibold my-md-4 my-3">
                  Daily News Trading
                </h2>
                <p className="sub_para">
                  Make profits quickly by utilizing big market movements when a
                  high-impact news is announced.
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <img src="assets/images/new_home/key4.svg" alt="" />
                <h2 className="heading_h6 fw-semibold my-md-4 my-3">
                  Reset & Top-up
                </h2>
                <p className="sub_para">
                  Reset or top-up your account to restart your trading journey,
                  even if you’ve violated any rules.
                </p>
              </div>
              <div className="col-lg-3 col-md-6">
                <img src="assets/images/new_home/key5.svg" alt="" />
                <h2 className="heading_h6 fw-semibold my-md-4 my-3">
                  Monthly Competition
                </h2>
                <p className="sub_para">
                  Aizelle hosts free monthly competitions where you can compete
                  for exclusive rewards.
                </p>
              </div>
              <div className="col-lg-3 d-lg-none d-block mt-0">
                <h3 className="heading_h5 fw-semibold my-md-4 my-3 text-uppercase">
                  There is more -
                </h3>
                <ul className="row g-md-4 g-2 p-0 ali_low list-unstyled sub_para">
                  <li className="col-sm-6 d-flex gap-2">
                    <img
                      src="assets/images/new_home/low.svg"
                      alt="low"
                      className="ali_row"
                    />
                    Low commissions
                  </li>
                  <li className="col-sm-6 d-flex gap-3">
                    <img
                      src="assets/images/new_home/row.svg"
                      alt="row"
                      className="ali_row"
                    />
                    Raw spreads
                  </li>
                  <li className="col-sm-6 d-flex gap-2">
                    <img
                      src="assets/images/new_home/best.svg"
                      alt="best"
                      className="ali_row"
                    />
                    Best packages
                  </li>
                  <li className="col-sm-6 d-flex gap-2">
                    <img
                      src="assets/images/new_home/Affordable.svg"
                      alt="Affordable"
                      className="ali_row"
                    />
                    Affordable
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <PackageItems />

        {/* <Platform /> */}
        {/* <Blog /> */}

        {/* <section className="section_padding faq" id="faq">
          <div className="container">
            <div className="row g-lg-5 g-2">
              <div className="col-lg-6">
                <h2 className="heading_h2 text-lg-start text-center mb-0">
                  Frequently Asked <br className="d-none d-lg-block" />{" "}
                  Questions
                </h2>
              </div>
              <div className="col-lg-6">
                <div className="accordion" id="accordionExample">
                  // First Accordion Item
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button faq_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What is Aizelle?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>Aizelle</strong> is a comprehensive trading
                        platform offering advanced tools, real-time data, and
                        robust security features for a superior trading
                        experience.
                      </div>
                    </div>
                  </div>
                  // Second Accordion Item
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed faq_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How do I start trading?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>Sign up</strong> on our platform, complete the
                        verification process, and start trading with our
                        user-friendly tools.
                      </div>
                    </div>
                  </div>
                  // Third Accordion Item
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed faq_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What makes Aizelle different from other platforms?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>Aizelle</strong> combines advanced technology,
                        user-friendly design, and exceptional support to provide
                        a unique trading experience.
                      </div>
                    </div>
                  </div>
                  // Fourth Accordion Item
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed faq_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        How secure is Aizelle?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>Aizelle</strong> uses state-of-the-art
                        encryption and security measures to protect your assets
                        and personal information.
                      </div>
                    </div>
                  </div>
                  // Fifth Accordion Item
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed faq_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        What is Algo Trading?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>Algo trading</strong> uses computer programs to
                        execute trades based on predefined rules and algorithms.
                        These algorithms can analyze market data, identify
                        patterns, and execute trades at high speeds, often
                        faster than human traders can react.
                      </div>
                    </div>
                  </div>
                  // Sixth Accordion Item
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed faq_btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Which is better, Manual Trading or Algo Trading?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body sub_para">
                        <strong>It depends.</strong> The best approach often
                        depends on individual preferences, risk tolerance, and
                        trading style. Many traders use a combination of both
                        methods. Algo trading can be used for high-frequency
                        trading and automated strategies, while manual trading
                        can be used for longer-term investments and
                        discretionary decisions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="section_padding yellow_bg" id="contact">
          <div className="container">
            <div className="row align-items-center g-4">
              <div className="col-12">
                <div className="text-center mb-xl-5 mb-3 text-white">
                  <h6 className="sub_heading">Contact Us</h6>
                  <h2 className="heading_h2">Connect with Aizelle</h2>
                  <p className="sub_para">
                    Contact our team for personalized support and guidance.{" "}
                    <br className="d-none d-md-block" /> We're committed to your
                    success and eager to hear from you.
                  </p>
                </div>
              </div>
              <div className="col-12 text-center mb-4">
                <div className="contact-form bg-white rounded w-100 pb-0">
                  <div className="d-flex align-items-end">
                    <div className="contact_img pb-2">
                      <img
                        src="assets/images/home/man.png"
                        alt="Contact Image"
                        className="d-md-block d-none  "
                      />
                    </div>
                    <div className="w-100 mb-5">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className={
                            validations.name && verror
                              ? "form-control rounded-left error_border "
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Your Name"
                          required=""
                          value={input.name}
                          onChange={handelInputs}
                        />
                        {validations.name && verror && (
                          <>
                            <p className="d-flex my-1 error_message sub_para">
                              <img
                                src="assets/images/home/warning.svg"
                                alt=""
                                className="me-1"
                              />
                              {verror}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className={
                            validations.email && verror
                              ? "form-control rounded-left error_border"
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Your Email"
                          required=""
                          value={input.email}
                          onChange={handelInputs}
                        />
                      </div>
                      {validations.email && verror && (
                        <>
                          <p className="d-flex my-1 error_message sub_para">
                            <img
                              src="assets/images/home/warning.svg"
                              alt=""
                              className="me-1"
                            />
                            {verror}
                          </p>
                        </>
                      )}
                      <div className="form-group">
                        <input
                          type="tel"
                          name="mobile"
                          className={
                            validations.mobile && verror
                              ? "form-control rounded-left error_border"
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Your Mobile"
                          required=""
                          value={input.mobile}
                          onChange={handelInputs}
                        />
                      </div>
                      {validations.mobile && verror && (
                        <>
                          <p className="d-flex my-1 error_message sub_para">
                            <img
                              src="assets/images/home/warning.svg"
                              alt=""
                              className="me-1"
                            />
                            {verror}
                          </p>
                        </>
                      )}
                      <div className="form-group">
                        <textarea
                          rows="4"
                          name="message"
                          className={
                            validations.message && verror
                              ? "form-control rounded-left error_border"
                              : "form-control rounded-left mb-lg-4 mb-3"
                          }
                          placeholder="Message"
                          required=""
                          value={input.message}
                          onChange={handelInputs}
                        ></textarea>
                      </div>
                      {validations.message && verror && (
                        <>
                          <p className="d-flex my-1 error_message sub_para">
                            <img
                              src="assets/images/home/warning.svg"
                              alt=""
                              className="me-1"
                            />
                            {verror}
                          </p>
                        </>
                      )}
                      <div className="d-flex">
                        <button
                          type="submit"
                          className="btn aizelle_btn sub_para"
                          onClick={handelubmit}
                        >
                          SUBMIT NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <div className="position-relative">
          {close ? (
            <div className="bg-black text-white p-2 d-flex align-items-center justify-content-center gap-3 aizelle_news">
              <p className="mb-0">
                Clients are provided with demo accounts that contain simulated
                funds for trading activities. Please note that all client
                trading operations are conducted in a simulated environment.
                More details can be found in the{" "}
                <a href="" className="text-white ">
                  FAQ section.
                </a>
              </p>
              <img
                src="assets/images/new_home/close.svg"
                alt=""
                className="img-fluid"
                onClick={() => setClose(false)}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <Footer />
        {/* <section
          className="section_padding position-relative why_Choose why_us_bg"
          id="whyus"
        >
          <div className="container-fluid">
            <div className="row g-xl-5">
              <div className="col-lg-12 text-center mb-xl-4 mb-3">
                <span className="sub_heading mb-2">Why Choose Us</span>
                <h2 className="heading_h2 mb-xl-5 mb-lg-4">
                  Unlock Your Trading Potential with
                  <br className="d-md-block d-none" /> Our Powerful Tools
                </h2>
              </div>
              <div className="col-lg-6 col-12 mx-auto text-center d-lg-block d-none">
                <img
                  src="assets/images/home/main.png"
                  alt=""
                  className="img-fluid main_img d-xl-block d-none"
                />
              </div>
              <div className="col-xl-6 col-12">
                <div className="row ">
                  <div className="col-xl-6 col-md-6 col-12 ">
                    <div className="why_choose_card ">
                      <div className="service_small_icon service_icon mb-2">
                        <img
                          src="assets/images/home/1.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Connecting Forex Traders Worldwide
                      </h4>
                      <p className="sub_para color_lightgray  mb-0">
                        Aizelle connects traders from over 180 countries,
                        reflecting the truly global nature of the forex market.
                        As forex remains the most liquid and accessible
                        financial market, our platform supports multiple
                        languages and offers localized trading tools.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="why_choose_card">
                      <div className="service_small_icon service_icon mb-2">
                        <img
                          src="assets/images/home/2.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Pioneering the Future of Forex Trading
                      </h4>
                      <p className="sub_para color_lightgray mb-0">
                        Aizelle envisions a future where forex trading is
                        seamlessly integrated with advanced technology and
                        accessible to all.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="why_choose_card">
                      <div className="service_small_icon service_icon  mb-2">
                        <img
                          src="assets/images/home/3.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Your Trading,
                        <br className="d-none d-lg-block" /> Your Way
                      </h4>
                      <p className="sub_para color_lightgray mb-0">
                        Every trader is unique, and so are their goals. aizelle
                        offers personalized trading solutions tailored to your
                        specific needs and aspirations. Whether you’re looking
                        to grow your portfolio or manage risk, we have the tools
                        and support to help you succeed.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="why_choose_card">
                      <div className="service_small_icon service_icon  mb-2">
                        <img
                          src="assets/images/home/4.png"
                          alt=""
                          className="img-fluid images"
                        />
                      </div>
                      <h4 className="heading_h6 color_lightblue my-xl-3 my-sm-2 my-1">
                        Commitment to Your Success
                      </h4>
                      <p className="sub_para color_lightgray mb-0">
                        Aizelle is committed to your success. Our innovative
                        platform, along with expert guidance, creates a trading
                        environment where your potential is maximized. We are
                        dedicated to providing you with the tools and support
                        needed to achieve consistent in the forex market.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}



        {/* <section className="core_value_section section_padding color_white">
          <div className="container">
            <div className="row justify-contant-center ">
              <div className="col-lg-12 text-center mb-xl-5 mb-3 ">
                <h2 className="heading_h2 mb-xl-4 mb-2">Features of Aizelle</h2>
                <p className="sub_para">
                  Unlock the full potential of our product with our amazing{" "}
                  <br className="d-none d-lg-block" /> features and top-notch.
                </p>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/Passive.png" alt="img-fluid" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Advaced Tools and Technology{" "}
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    We provide a user-friendly dashboard interface with
                    back-testing tools, predefined screeners, and technical
                    software to comprehend market trends and test your
                    strategies.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img
                      src="assets/images/home/Diversification.png"
                      alt="img"
                    />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Personalised Trading Strategies
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    Pick from our pre-defined strategies, such as scalping,
                    multi-leg, momentum-based, statistical arbitrage, index fund
                    rebalancing, etc., or seek guidance from our advisory team.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/Risk.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">Build Logic</h6>
                  <p className="sub_para sub_para color_gray">
                    From Simple to complex strategies. Build all kinds of
                    trading strategies from technical indicators to greeks using
                    just keywords. Get a real-time snapshot of your strategies
                    performance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/TotalSecurity.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Innovation, Integrity, and Forex Excellence
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    aizelle core principles—innovation, integrity, and
                    excellence—drive our commitment to enhancing your trading
                    experience. In a market where currency exchange rates can
                    fluctuate by over 30% annually.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/synced.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Empowering Forex Traders with Precision and Flexibility{" "}
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    At aizelle, our mission is to equip traders with the tools
                    and strategies needed to thrive in the dynamic forex market.
                    With a daily trading volume exceeding $6.6 trillion, the
                    forex market demands precision and adaptability.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="why_choose_card">
                  <div className="service_icon">
                    <img src="assets/images/home/skin5.png" alt="img" />
                  </div>
                  <h6 className="heading_h6 my-xl-3 my-2">
                    Informed Decisions for Optimal Forex Outcomes
                  </h6>
                  <p className="sub_para sub_para color_gray">
                    Leverage aizelle's data-driven strategies to navigate the
                    complexities of the $5 trillion daily traded forex market.
                    Our cutting-edge analytics tools sift through vast amounts
                    of market data to deliver actionable insights in real-time,
                    helping you make informed decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="section_padding about_bg">
          <div className="container">
            <div className="row align-items-center g-lg-5 g-3">
              <div className="col-12 mb-xl-5 mb-3">
                <h2 className="heading_h2 text-center">How does It Works ?</h2>
                <p className="text-center sub_para">
                  Register, Complete your documentation and leave the rest for
                  us to provide for you.
                </p>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <img
                  src="assets/images/home/about1.png"
                  alt="Illustration"
                  className="img-fluid"
                />
              </div>

              <div className="col-12 col-lg-6">
                <div className="steps">
                  <div className="step mb-4 d-flex align-items-start shadow rounded bg-white">
                    <div className="number_padding text-white rounded-circle d-flex align-items-start justify-content-center">
                      <span className="d-flex align-items-start justify-content-start sub_para">
                        1
                      </span>
                    </div>
                    <div className="step-content ms-3">
                      <h5 className="heading_h5">
                        Register / Login To Our Platform
                      </h5>
                      <p className="sub_para color_lightgray mb-0">
                        Begin by brainstorming and developing your trading
                        strategy. Identify market conditions, asset classes, and
                        specific trading goals.
                      </p>
                    </div>
                  </div>
                  <div className="step mb-4 d-flex align-items-start shadow rounded bg-white">
                    <div className="number_padding text-white rounded-circle d-flex align-items-center justify-content-center">
                      <span className="d-flex align-items-start justify-content-start sub_para">
                        2
                      </span>
                    </div>
                    <div className="step-content ms-3">
                      <h5 className="heading_h5">
                        Access and Pre Build Strategys
                      </h5>
                      <p className="sub_para color_lightgray mb-0">
                        Choose from pre-built trading algorithms or create a
                        custom strategy tailored to your unique trading style
                        and goals.
                      </p>
                    </div>
                  </div>
                  <div className="step mb-4 d-flex align-items-start shadow bg-white">
                    <div className="number_padding text-white rounded-circle d-flex align-items-center justify-content-center">
                      <span className="d-flex align-items-start justify-content-start sub_para">
                        3
                      </span>
                    </div>
                    <div className="step-content ms-3">
                      <h5 className="heading_h5">
                        Start Strategy Testing and Approval
                      </h5>
                      <p className="sub_para color_lightgray mb-0">
                        Test your strategy using historical data to assess its
                        effectiveness. After thorough testing, review the
                        strategy based on performance reports. Make necessary
                        adjustments to ensure it meets your trading criteria and
                        risk tolerance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="section_padding section_testimonal">
          <div className="container">
            <div className="d-flex gap-2 justify-content-end align-items-center">
              <div className="btn_about_prev swiper-button-about-prev">
                <img
                  src="assets/images/home/rightwhitearrow.svg"
                  alt="Previous"
                  className="text-white"
                />
              </div>
              <div className="btn_about_next swiper-button-about-next">
                <img src="assets/images/home/leftwhitearrow.svg" alt="Next" />
              </div>
            </div>
            <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-about-next",
                prevEl: ".swiper-button-about-prev",
              }}
              loop={true}
            >
              <SwiperSlide>
                <div className="testimonal_card">
                  <div className="row align-items-center g-lg-5 g-4">
                    <div className="col-lg-3 col-md-5">
                      <div className="d-block w-100">
                        <img
                          src="assets/images/home/test_1.png"
                          alt="Testimonial"
                          className="img-fluid w-100 mx-auto mx-lg-0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <p className="sub_para">
                        Thankyou for making it a wonderful journey for me. My
                        discretionary trades did not went well but all algo
                        tardes with your portal did great Job. Henceforth no
                        discretionary tardes only aizelle😀
                      </p>
                      <h4 className="heading_h6">Mobarok Hossain</h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonal_card">
                  <div className="row align-items-center g-lg-5 g-4">
                    <div className="col-lg-3 col-md-5">
                      <div className="d-block w-100">
                        <img
                          src="assets/images/home/test_1.png"
                          alt="Testimonial"
                          className="img-fluid w-100 mx-auto mx-lg-0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <p className="sub_para">
                        “Interface is very seamless and smooth.. one more thing
                        charges are very low..😍😍😍 ”
                      </p>
                      <h4 className="heading_h6">Robert S. Dennis</h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonal_card">
                  <div className="row align-items-center g-lg-5 g-4">
                    <div className="col-lg-3 col-md-5">
                      <div className="d-block w-100">
                        <img
                          src="assets/images/home/test_1.png"
                          alt="Testimonial"
                          className="img-fluid w-100 mx-auto mx-lg-0"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <p className="sub_para">
                        Super-fast execution. Proper support and guidance from
                        aizelle Team whenever I stuck in my strategy.
                      </p>
                      <h4 className="heading_h6">Mike C. Escalante</h4>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section> */}

        {/* <section className="section_padding">
                    <div className="container">
                        <div className="row align-items-center g-0 ">
                            <div className="col-12 mb-xl-5 mb-3 h-100" >
                                <h2 className="heading_h2 text-center">Join our community</h2>
                            </div>
                            <div className="col-12 col-lg-6  text-white  ">
                                <div className="monthly h-100 join_community">
                                    <h6 className="heading_h6 mb-lg-3 mb-2">Monthly Subscription</h6>
                                    <h5 className="heading_h5 mb-lg-3 mb-2"><h2 className="heading_h2">$29</h2>per month</h5>
                                    <p className="sub_para mb-0">Full access for less than $1 a day</p>
                                    <button className="btn aizelle_btn_border mt-4">Sign Up</button>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6">
                                <div className="why_us h-100 join_community">
                                    <h6 className="heading_h6 mb-xl-4 mb-lg-3 mb-2">Why Us</h6>
                                    <ul className="list-unstyled sub_para mb-lg-3 mb-0">
                                        <li>Tutorials by industry experts</li>
                                        <li>Peer & expert code review</li>
                                        <li>Coding exercises</li>
                                        <li>Access to our GitHub repos</li>
                                        <li>Community forum</li>
                                        <li>Flashcard decks</li>
                                        <li>New videos every week</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
        {/* <section className="section_padding overflow-hidden position-relative mission_section why_Choose">
                    <div className="container">
                        <div className="row g-md-4 g-3 pe-md-3 py-lg-5">
                            <div className="col-lg-6">
                                <div className="cards_style hover-effect me-xl-2">
                                    <div className="d-flex justify-content-center">
                                        <img src="assets/images/home/our_mission_image.png" alt="" className="img-fluid mb-lg-3 mb-2" />
                                    </div>
                                    <h6 className="heading_h6 text-center">Our Mission</h6>
                                    <p className="sub_para text-center">Tempora incidunt ut labore et dolore magnam aliruam quaerat volurtatem aut enim <br className="d-lg-block d-none" /> ad minima veniam ruis  nostrum exercitatio.</p>
                                </div >
                            </div >
                            <div className="col-lg-6">
                                <div className="cards_style hover-effect">
                                    <div className="d-flex justify-content-center ">
                                        <img src="assets/images/home/our_vision_image.png" alt="" className="img-fluid mb-lg-3" />
                                    </div>
                                    <h6 className="heading_h6 text-center">Our Vision</h6>
                                    <p className="sub_para text-center">Quis autem vel eum iure reprehenderit ruin voluta velit esse quam nihil molestiae conseuatur illum aui dolorem <br className="d-xl-block d-none" />  eum fugiat ruo.</p>
                                </div>
                            </div>
                            <div className="mb-0 position-absolute left_right_shape top_right_shape">
                                <img src="assets/images/home/mission_vision_background_shape.png" alt="" className="img-fluid d-lg-block d-none" />
                            </div>
                        </div >

                        <div className="mb-0 position-absolute mision_left_right_shape top_bottom_shape ">
                            <img src="assets/images/home/mission_vision_left_shape.png" alt="" className="img-fluid d-lg-block d-none" />
                        </div>
                    </div >
                </section > */}

        <ScrollTo smooth={true} className="aizelle_top" />
      </ScrollToTop>
    </>
  );
}
