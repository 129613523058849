import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollTo from "react-scroll-to-top";
import ScrollToTop from "../component/ScrollToTop";
export default function TermsConditions() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_padding privacypolicy">
                    <div className="container">
                        <div className="scroll">
                            <div className="scroll-inner">
                                <h2 className="heading_h2 mb-lg-4 mb-3">
                                    Risk Disclaimer and Warning
                                </h2>
                                <div className="sub_para">
                                    <p>
                                        Trading in financial markets involves substantial risk and may not be suitable for all investors. Please read and understand the following risk disclosure before engaging in any trading activities with Aizelle:  </p>

                                    <p>
                                        1.High Risk: Trading forex, cryptocurrencies, stocks, and other financial instruments carries a high level of risk and can result in the loss of all your invested capital. Only trade with funds you can afford to lose.
                                    </p>
                                    <p>
                                    2.Past Performance: Past performance is not indicative of future results. The performance of any trading system or methodology is not guaranteed and can vary widely depending on market conditions.

                                    </p>
                                    <p>
                                    3.Leverage Risk: The use of leverage can work against you as well as for you. It can lead to large losses as well as gains.
                                    </p>
                                    <p>
                                    4.Market Volatility: Financial markets can be highly volatile and unpredictable. Prices may fluctuate rapidly, potentially resulting in substantial losses.

                                    </p>
                                    <p>
                                    5.No Guarantees: There is no guarantee of profit in trading. Any claims of guaranteed returns should be treated with extreme caution.
                                    </p>
                                    <p>
                                    6.Technical Issues: Trading platforms and internet connectivity issues can affect trade execution and account access. Aizelle is not responsible for any losses incurred due to system or connection failures.
                                    </p>
                                    <p>
                                    7.Regulatory Changes: Changes in laws, regulations, or trading rules can impact your trading activities and potential profitability.

                                    </p>

                                    <p>
                                    8.Educational Purpose: Any educational materials, webinars, or trading strategies provided are for informational purposes only and should not be considered as financial advice.

                                    </p>
                                    <p>
                                    9.Individual Responsibility: You are solely responsible for your trading decisions. Aizelle and its representatives do not make trading decisions on your behalf.
                                    </p>
                                    <p>
                                    10.Seek Professional Advice: We strongly recommend consulting with a licensed financial advisor before making any investment decisions.
                                    </p>

                                    <p className="mt-3">
                                    By participating in Aizelle's trading programs, tournaments, or using our platforms, you acknowledge that you have read, understood, and agree to this risk disclaimer. Trade responsibly and within your means.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
                <ScrollTo smooth={true} className="aizelle_top" />
            </ScrollToTop>
        </>
    );
}
