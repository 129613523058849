import React from "react";
import Marquee from "react-fast-marquee";

export default function PaymentMarquee() {
  const paymentMethods = [
    { id: 1, src: "assets/images/new_home/payment-1.png" },
    { id: 2, src: "assets/images/new_home/payment-2.png" },
    { id: 3, src: "assets/images/new_home/payment-3.png" },
    { id: 4, src: "assets/images/new_home/payment-4.png" },
    { id: 5, src: "assets/images/new_home/payment-5.png" },
    { id: 6, src: "assets/images/new_home/payment-6.png" },
    { id: 7, src: "assets/images/new_home/payment-7.png" },
    { id: 8, src: "assets/images/new_home/payment-9.png" },
    { id: 9, src: "assets/images/new_home/payment-10.png" },
    { id: 10, src: "assets/images/new_home/payment-11.png" },
  ];

  return (
    <section className="section_padding mt-md-5 mt-3">
      <div className="container">
        <h2 className="heading_h6 text-center mb-xl-5 mb-lg-4 mb-3">
          Available Payment Methods
        </h2>
        <div className="payment-container">
          <div className="left_shadow"></div>
          <Marquee
            speed={50}
            gradient={false}
            pauseOnHover={false}
            className="payment d-flex justify-items-center align-items-center mx-auto"
          >
            {paymentMethods.map((method) => (
              <div key={method.id} className="payment-card">
                <img src={method.src} alt={`Payment Method ${method.id}`} />
              </div>
            ))}
          </Marquee>
          <div className="right_shadow"></div>
        </div>
      </div>
    </section>
  );
}

