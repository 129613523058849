import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";


export default function Faq() {
  const faqData = [
    {
      id: 1,
      question: "How often will I get my Payout?",
    },
    {
      id: 2,
      question: "What Is The Copy Trading Rule at Aizelle?",
    },
    {
      id: 3,
      question: "What are the Restricted/Prohibited Trading Strategies? ",
    },
    {
      id: 4,
      question: "How can I calculate the Daily Drawdown?",
    },
    {
      id: 5,
      question: "Is News Trading allowed at Aizelle?",
    },
    {
      id: 6,
      question: "How can I complete the KYC verification process?",
    },
    {
      id: 7,
      question: "What will be my Profit Share from the Stellar Lite Challenge?",
    },
    {
      id: 8,
      question: "Does Aizelle offer a Scale-Up plan?",
    },
    {
      id: 9,
      question: "How can I Withdraw my profits?",
    },
    {
      id: 10,
      question: "Aizelle Free Trial Rules",
    },
    // Add more FAQ data here
  ];
  const navigate = useNavigate();

  const handleQuestionClick = (id, question) => {
    document.title = ` ${question}`;

    navigate(`/article/${id}`);
  };
  return (
    <>
       <ScrollToTop>
      <Header />
      {/* <section className="">
        <div className="container mt-5">
          <div className="faq_main">
            <div className="faq_body">
              <h5 className="faq_art mt-4 mb-4 pl-2">Most Viewed Articles</h5>
              <div className="row">
                {faqData.map((faq, index) => (
                  <div className="col-md-6" key={index}>
                    <ul className="list-unstyled heading_h5">
                      <li className="faq_list">
                        <a
                          href="#"
                          onClick={() => handleQuestionClick(faq.id, faq.question)}
                          className="faq-link faq_question"
                        >
                          {faq.question}
                        </a>
                        <img src="assets/images/new_home/faqarrow.svg" alt="" />
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="section_padding pt-0">
        <div className="container mt-5">
          <div className="row g-4">
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg1.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      About Aizelle
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg2.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Trading Rules & Guidelines
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg1.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Aizelle Challenge Insight
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg3.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Aizelle Features
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg1.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Aizelle Dashboard FAQ
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg1.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">General FAQ</h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg1.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Affiliate & Payment Partner FAQ
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg4.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Aizelle Infinity Points
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg5.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">
                      Video Library
                    </h5>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="#" className="text-decoration-none ">
                <div className="faq_card why_choose_card d-flex align-items-center">
                  <div className="faq_card_img p-3">
                    <img
                      src="assets/images/new_home/faqimg6.svg"
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="faq_card_heading">
                    <h5 className="faq_title text-dark heading_h5">Refer & Earn</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      </ScrollToTop>
    </>
  );
}
