import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { faFacebookF, faPinterestP, faTwitter } from '@fortawesome/free-brands-svg-icons';


export default function Footer() {
    const handleScrollToSection = (sectionId) => {
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }
    return (
        <>
            <footer className="aizelle_footer ">
                <div className="aizelle_footer_top sub_para">
                    <div className="container">
                        <div className="row align-items-start g-md-5 g-lg-2 g-4">
                            <div className="col-lg-4 col-md-12">
                                <Link to={"/"}>
                                    <img src="/assets/images/logo/logo.svg" alt="aizelle" className="img-fluid" />
                                </Link>
                                <p className="sub_para mt-3">
                                Aizelle is a dynamic proprietary trading firm that empowers traders to reach new heights in the financial markets.
                                </p>
                            </div>
                            <div className="col-lg-2 offset-lg-1 offset-md-0 col-md-3 col-6">
                                <h6 className="heading_h6 mb-lg-4  mb-3">Services</h6>

                                <ul className="list-unstyled mb-0">
                                    <li className="mb-lg-3 mb-2">
                                        <Link to="/" className="text-decoration-none text-dark  sub_para">Home</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("whyus")}>Why us</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("service")}>Service</Link>
                                    </li>
                                    {/* <li className="mb-lg-3 mb-2">
                                        <Link to="/faq" className="text-decoration-none text-dark  sub_para"
                                            onClick={() => handleScrollToSection("faq")}
                                        >Faq</Link>
                                    </li> */}
                                    <li className="mb-lg-3 mb-2">
                                        <Link href="" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("blog")} >Blog</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 offset-md-0 col-md-3 col-6">
                                <h6 className="heading_h6 mb-lg-4  mb-3">More</h6>
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-lg-3 mb-2">
                                        <Link to="/privacypolicy" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("Privacypolicy")}>Privacy Policy</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link to="/termsconditions" className="text-decoration-none text-dark  sub_para" onClick={() => handleScrollToSection("TermsConditions")}>Terms & Conditions</Link>
                                    </li>
                                    <li className="mb-lg-3 mb-2">
                                        <Link to="/riskDisclaimer-and-warning" className="text-decoration-none text-dark  sub_para" >
                                            Risk Disclaimer &  <br />Warning
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 offset-md-0">
                                <h6 className="heading_h6 mb-lg-4 mb-3">Contact Us</h6>


                                <p className="d-flex gap-2 align-items-center">
                                    <div className="number_padding text-white rounded-circle d-flex align-items-start justify-content-center">
                                        <img src="/assets/images/footer/email.svg" alt="" />
                                    </div>
                                    <a className="text-decoration-none text-dark" href="mailto:info@aizelle.com">
                                        info@aizelle.com
                                    </a>
                                </p>
                                <p className="d-flex gap-2 align-items-center">
                                    <div className="number_padding text-white rounded-circle d-flex align-items-start justify-content-center">
                                        <img src="/assets/images/footer/address.svg" alt="" />
                                    </div>
                                    <Link className="text-decoration-none text-dark">
                                    Office - C1 - 1F - SF2542,Ajman
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="aizelle_footer_bottom overflow-hidden">
                    <div className="container">
                        <div className="row align-items-center pb-xl-0 pb-md-3 pb-0">
                            <div className="col-12 border_hr my-md-4 my-2 w-100 h-100"></div>

                            <div className="col-xl-6 col-md-6 mt-md-0 mt-3 mt-0 p-0">
                                <p className="sub_para text-md-start text-center mb-0">
                                    © 2025 Aizelle. All Rights Reserved
                                </p>
                            </div>
                            <div className="col-xl-6 col-md-6 mt-md-0 mt-2 p-0">
                                <ul className="text-decoration-none d-flex gap-2 list-unstyled justify-content-md-end justify-content-center mb-0">

                                    <li>
                                        <Link className="color_white fotter_social_icon">
                                            <FontAwesomeIcon icon={faFacebookF} style={{ color: "#000000" }} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="color_white fotter_social_icon">
                                            <FontAwesomeIcon icon={faTwitter} style={{ color: "#000000" }} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="color_white fotter_social_icon">
                                            <FontAwesomeIcon icon={faPinterestP} style={{ color: "#000000" }} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}