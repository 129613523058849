import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Privacypolicy from './pages/Privacypolicy';
import TradeHistory from './pages/TradeHistory';
import Main from './pages/Main';
import Faq from './pages/Faq';
import Articles from './pages/Articles';
import TermsConditions from './pages/TermsConditions';
import Freecompetition from './pages/Freecompetition';
import TradeMasterCup from './pages/TradeMasterCup';
import RiskDisclaimer from './pages/RiskDisclaimer'
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path="/main" index element={<Main />} />
      <Route path='/trade_history' element={<TradeHistory/>}/>
      <Route path="/privacypolicy" element={<Privacypolicy />} />
      <Route path="/termsconditions" element={<TermsConditions />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/article/:id" element={<Articles />} />
      <Route path="/free_competition" element={<Freecompetition />} />
      <Route path="/trade-master-cup" element={<TradeMasterCup />} />
      <Route path="/riskDisclaimer-and-warning" element={<RiskDisclaimer />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
