import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollTo from "react-scroll-to-top";
import ScrollToTop from "../component/ScrollToTop";
export default function TermsConditions() {
  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_padding privacypolicy">
          <div className="container">
            <div className="scroll">
              <div className="scroll-inner">
                <h2 className="heading_h2 mb-lg-4 mb-3">
                  Terms & Conditions
                </h2>
                <div className="sub_para">
                  <p>
                    1.Acceptance of Terms
                    By accessing or using Aizelle's services, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the service.
                  </p>

                  <p>
                    2.Eligibility
                    You must be at least 18 years old and legally able to enter into contracts to use our services. By using our platform, you represent and warrant that you meet these requirements.
                  </p>
                  <p>
                    3.Account Registration
                    You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.
                  </p>
                  <p>
                    4.Trading Rules and Regulations
                    Users must adhere to all trading rules set forth by Aizelle, including but not limited to profit targets, loss limits, and trading durations. Violation of these rules may result in account termination.
                  </p>
                  <p>
                    5.Funds and Payouts
                    All funds and payouts are subject to our verification process. We reserve the right to withhold payouts if we suspect any fraudulent activity or violation of our terms.
                  </p>
                  <p>
                    6.Risk Acknowledgment
                    You acknowledge that trading involves significant risk of loss and is not suitable for all investors. You trade at your own risk.
                  </p>
                  <p>
                    7.Intellectual Property
                    All content on the Aizelle platform, including text, graphics, logos, and software, is the property of Aizelle and protected by intellectual property laws.
                  </p>
                  <p>
                    8.Prohibited Activities
                    Users are prohibited from engaging in any illegal activities, market manipulation, or any action that could damage, disable, or impair our services.
                  </p>

                  <p>
                    9.Termination of Service
                    Aizelle reserves the right to terminate or suspend your account at any time for any reason without prior notice.
                  </p>
                  <p>
                    10.Modifications to Service
                    We reserve the right to modify or discontinue, temporarily or permanently, the service with or without notice.
                  </p>

                  <p>
                    11.Limitation of Liability
                    Aizelle shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of the service.
                  </p>

                  <p>
                    12.Governing Law
                    These Terms shall be governed by and construed in accordance with the laws of UAE, without regard to its conflict of law provisions.
                  </p>
                  <p>
                    13.Changes to Terms
                    We reserve the right to update or change our Terms and Conditions at any time. Continued use of the service after any such changes shall constitute your consent to such changes.
                  </p>

                  <p>
                    14.Contact Information
                    For any questions about these Terms, please contact us at [contact email].
                    By using Aizelle's services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <ScrollTo smooth={true} className="aizelle_top" />
      </ScrollToTop>
    </>
  );
}
